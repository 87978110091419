app.jq = {
	version: function() {
		return jQuery().jquery;
	},
	isLast: function() {
		return app.jq.version() >= '3.4.1';
	},
	cssVersion: function (paramId) {
		if (!paramId) {
			paramId = '--udu__less-version';
		}
		try {
			return $(':root').css(paramId).replace(/[" ]/g, '');
		} catch(e) {
			return 'udu version not defined';
		}
	}
};