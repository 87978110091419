"use strict";

jQuery.migrateMute = true;

$(
	function() {
		$(document).on('yarl-before-load', function (e, loadQueue) {
			if (loadQueue.url.match(/tinymce/g)) {
				loadQueue.cancelLoad = true;
			}
		});
		app.initializeJSFunctionality();
	}
);

if (!window.app) {
	var app = {};
}

/**
 * Some settings for General Variables
 */
if (!app._vars) {
    app._vars = {};
}
app._vars.__coreVersion = '2.0.1';
app._vars.uduDelim = '<!--UDU-INCL-DLM:--bb4df1ea-1ab7-4e5e-87f6-366e53ee6892-05cec099f850-->';
if (!app._VERSION) {
    app._VERSION = 1;
}
app.optionsWereInitialized = false;
app.productAvailableQuantityUrl = null;
app.platformType = 'ES';
if (!app.messages) {
	app.messages = {};
}

/*
* Some messages are defined on Template level
*/
app.udu_generalMessages = '_form_message_See_errors,_form_message_Required_field,_cart_error_Checkout_Message';

/**
 * Returns top window
 *
 * @returns {{}}
 */
app.top = function() {
	var wnd = window;
	try {
		for (var i = 0; i < 6; i++) {
			if (wnd !== wnd.parent && (wnd.parent.app || wnd.parent.admin)) {
				wnd = wnd.parent;
			} else {
				break;
			}
		}
	} catch(e) {

	}
	return wnd;
};

if (!app.ajaxProcessURLs) {
	app.ajaxProcessURLs = app.top().app.ajaxProcessURLs;
}

/**
 * Defines if we call form in AJAX Mode
 *
 * @param {bool} isAjax
 * @returns {void}
 */
app.asAjax = function(isAjax) {
	var that = this;
	if (isAjax) {
		clearTimeout(this._ajaxTimeoutId);
		this.cookies.createCookie('udu_ajaxMode', 'Y');
	} else {
		this._ajaxTimeoutId = setTimeout(
			function() {
				that.cookies.createCookie('udu_ajaxMode', 'N');
			},
			100
		)
	}
};

/**
 * Defines if it's modern browers
 * @returns {boolean}
 */
app.goodBrowser = function() {
    if (this.isMobile()) {
        return false;
    }
    return /Chrome/.test(navigator.userAgent) || navigator.userAgent.toLowerCase().indexOf('firefox') > -1
};

/**
 * Performs Link Popup Functionality
 */
app.makePopupLinks = function() {
    var $items = $('a._udu-popup');
    if ($items.length > 0) {
    	app.getScript(
    		'main.link.popup.js',
			function() {
    			app._makePopupLinks($items);
			}
		);
    }
    if (String(document.location).indexOf('_udu-pmd=pop') > 0) {
    	$('a').attr('target', '_parent');
	}
};

/**
 * Returns Document Referrer
 *
 * @returns {string}
 */
app.docReferrer = function() {
	return app.getLData('documentReferrer');
};

/**
 * Assigns necessary JS handlers to different elements in templates
 * template code does not contain any JS
 *
 * @returns {void}
 */
app.initializeJSFunctionality = function() {
	if (app.storeId) {
		app.setLData('_uduG__SID', app.storeId);
	}
	setTimeout(
		function() {
			$('body').addClass('_done-2s');
		},
		1200
	);
	if (this._initializeJSFunctionality_done) {
		return false;
	}
	if (app.goodBrowser()) {
		$('body').addClass('udu__modern-browser');
	}
    app._vars.keys = {};
	app.events.initBaseEvents();
	var $body = $('body');
    $body.keyup(
    	function(e) {
            app._vars.keys.ctrlKey = false;
            app._vars.keys.escape = false;
            app.events.trigger('udu_KEY-CTRL-UNPRESS', {});
            app.events.trigger('udu_KEY-ESCAPE-UNPRESS', {});
        }
	);
	try {
        app._vars.__observer = new MutationObserver(
            function(mutations) {
                app.events.trigger(
                    'udu_DOC-MUTATION',
                    {
                        muts: mutations
                    }
                )
            }
        );
        app._vars.__observer.observe(document, {
            childList: true,
            subtree: true,
            characterDataOldValue: true
        });
	} catch(e) {

	}

	app.url.getRestUrl();

	$('html').attr('lang', app.getLang());

    $body.keydown(
    	function (e) {
			if (e.ctrlKey) {
				app._vars.keys.ctrlKey = true;
				app.events.trigger('udu_KEY-CTRL-PRESS', {});
			} else if (e.key === 'Escape') {
                app._vars.keys.escape = true;
                app.events.trigger('udu_KEY-ESCAPE-PRESS', {});
			}
    	}
    );
	if (this.search) {
       this.search.init($('.top .div-search-field, #advanced-search-form'));
	}
    this._initializeJSFunctionality_done = true;
	var that = this;
	that.processAllJSON();
	that.makePopupLinks();
    that.fixBootStrap();
    this.popup.initMsgPopups();
    that.saveProductListInfo();
	$('.budgetId table').addClass('tbl-bordered small-font list');
	app.utils.table.makeSortable($('table.tbl-bordered:not(.udu__not-sortable), table.shipping-methods'));
	if (app.accessibilty) {
		app.accessibilty.init();
	}
	if (!this.ajaxProcessURLs) {
		this.ajaxProcessURLs = app.top().app.ajaxProcessURLs;
	}
	$('a.udu-splash-enable, li.udu-splash-enable > div > a').mousedown(
		function() {
			app.splash.enable();
		}
	);
	if ($('table.list div.message.error').length > 0) {
		$('.checkout-link a.button.checkout').click(function(event) {
			that.alert(that.messages._cart_error_Checkout_Message);
			event.preventDefault();
		})
	}
	/*in case we login through Checkout Page*/
	var $logLink = $('.preview-order-note a[href*="user/log-in"]');
	if ($logLink.length > 0) {
		app.getURL('checkout');
		$logLink.mousedown(
			function() {
				var url = app.getURL('checkout');
				if (url) {
					that.setLData('redirectAfterLoginUrl', url);
				}
			}
		)
	}
	// we are on login page
	if (app.sso) {
		// if we login on checkout page - we go back to it after login
		if (app._vars._layURI) {
			app.getURL(
				'checkout',
				function(url) {
					var redUrl = that.getLData('redirectAfterLoginUrl');
					// if we are on checkout page - we will redirect to it
					if (String(app._vars._layURI).indexOf(url) >= 0) {
						app.setLData('redirectAfterLoginUrl', app._vars._layURI);
					// if we came from checkout page
					} else if (String(document.referrer).indexOf(url) >= 0) {
						app.setLData('redirectAfterLoginUrl', url);
					} else if (redUrl.indexOf(url) < 0) {
						app.setLData('redirectAfterLoginUrl', '');
					}
				}
			);
		}
	} else if (!app._usrLogged) {
		app.setLData('redirectAfterLoginUrl', '');
	}
	var $btn = $('#btnAddDiscountCode');
	if ($btn.length > 0) {
		$btn.prop("disabled", false);
		$btn.closest("form")
			.submit(
				function(e) {
					app.addDiscountCode(this);
					e.preventDefault();
					return false;
				}
			)
	}
	$('._ .tax-table button.remove')
		.prop("disabled", false)
		.click(
			function() {
				app.removeDiscountCode($(this).data('udu-component-title'));
			}
		);

    $('td.description .prod-cart-description').each(
    	function () {
            var $item = $(this);
            if ($item.height() >= 200) {
                $item.click(
                    function() {
                        var isOpened = $item.hasClass('opened');
                        var $top = $('._ > .top');
                        var pos = $top.css('position');
                        var scrollFix = -40;
                        if (pos === 'fixed') {
                            scrollFix -= $top.height();
                        }
                        if (isOpened) {
                            $item.removeClass('opened');
                            app.utils.effects.scrollTop($item.offset().top + scrollFix);
                        } else {
                            $item.addClass('opened');
                        }
                    }
                )
            } else {
                $item.addClass('opened no-expand');
            }
            $item.addClass('ready');
        }
	);

	$('div[data-udu-messages],table[data-udu-messages]').each(
		function() {
			var wrd = $(this).data('udu-messages').split(',');
			for (var i = 0; i < wrd.length; i++) {
				if (!that.messages[wrd[i]]) {
					that.messages[wrd[i]] = null;
				}
			}
		}
	);
	var $bootstrap = $('.bootstrap');
	if ($bootstrap.length > 0 && String($bootstrap.data('widget-activate-on-url')).indexOf('user/log-in') > 0){
		app.udu_generalMessages += ',udu_logInRedirectPage';
	}
	$('table .item-controls select.prod-quantity').change(
		function() {
			$(this).closest('form').submit();
		}
	);
	setTimeout(
		function() {
			app.setLData('documentReferrer', String(document.location));
		},
		1500
	);

	if ($('._ fieldset.options .option-image').length === 1) {
		$('._ fieldset.options select.mandatory, ._ fieldset.options select.optional').change(
			function() {
				app.setProductOptionsImage($(this).val());
			}
		);
		app.setIniProductOptionsImage();
	}
	// general messages on template level
	var tmp = app.udu_generalMessages.split(',');
	for (var i = 0; i < tmp.length; i++) {
		if (!that.messages[tmp[i]]) {
			that.messages[tmp[i]] = null;
		}
	}
	$('form input').focus(
		function() {
			$(this).closest('tr.error')
				.removeClass('error');
		}
	);

	var h = $('.warn-1 form input').outerHeight();
	if (Number(h)) {
		$("<style type='text/css'> " +
			".warn-1 form tr.error input {background-position: right " + ((h - 20) / 2) + "px center !important}" +
			"</style>")
			.appendTo("head");
	}
	this.initializeMessages();
	$('.make-enabled').attr('disabled', false);
	var $alertTxt = $('div.app-alert-popup');
	if ($alertTxt.length > 0 && $alertTxt.html() && !app._vars._uduRedirectingInProgress) {
		this.alert($alertTxt.html(), null, {cssClass: 'fullMode'});
	}

	var $redWarn = $('.dev-mode-warning');
	if ($redWarn.length > 0) {
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/js/main.topWarn.js'
			],
			function () {
				app.topWarn.init($redWarn);
			}
		);
	};
	if (app.prodExtData && app.prodExtData.groupedProducts) {
		var cat = '';
		var sku = '';
		if (app.prodExtData.groupedProducts.sku) {
			sku = app.prodExtData.groupedProducts.sku;
		}
		if (app.prodExtData.groupedProducts.category) {
			cat = app.prodExtData.groupedProducts.category;
		}
		$.post(
			app.ajaxProcessURLs.process,
			{
				op: 'grouped-products',
				cat: cat,
				sku: sku,
				prodId: app.prodInfo.id
			},
			function(str) {
				$('.grouped-products-wrapper .grouped-products-inner').html(str);
			}
		);
	}
	var $err = $('div.message.error');
	if ($err.length > 0) {
		var pos = $err.position();
		app.utils.effects.scrollTop(pos.top);
	}
	if (this.goodBrowser()) {
		$('.user-register input[type="password"]')
			.val('')
			.attr('readonly', true)
			.focus(
			function() {
				$(this).attr('readonly', false);
			}
		).blur(
			function() {
				$(this).attr('readonly', true);
			}
		);
	}

	app.utils.initQuantityButtons();

	var $prodComment = $('.prod-comments textarea#product-comment');
	if ($prodComment.length === 1) {
		var $prodData = $('#comment-data-100');
		var $prodInputs = $('.prod-comments input');
		$prodComment.change(
			function() {
				$prodData.val(btoa($(this).val()));
				if ($(this).val()) {
					$prodInputs.attr('disabled', false);
				} else {
					$prodInputs.attr('disabled', true);
				}
			}
		);
		$prodComment.val(atob($prodData.val()));
		if ($prodData.val()) {
			$prodInputs.attr('disabled', false);
		} else {
			$prodInputs.attr('disabled', false);
		}
	}
	$(document).mouseup(
		function() {
			clearTimeout(app.btnQuantTimeoutId);
			clearInterval(app.btnQuantIntervalId);
		}
	);
	app.changeProdQuantity(0);
	app.setMinWindSize();
    app.applyProdListAttrs();
	$(window).resize(
		function() {
			app.setMinWindSize();
			app.popup.setMaxHeight();
		}
	);

	$('input, textarea, select').keyup(
		function() {
			$(this).removeClass('invalid-item');
		}
	).change(
		function() {
			$(this).removeClass('invalid-item');
		}
	);
	app.fixIE11(true);
	if (!app.initializeBudgetLink) {
        app.budgets.init();
	}

	if (app.bootstrap) {
        app.bootstrap.init();
	}
};

app.budgets = {
	init: function() {
		var $link = $('#myBudgetsLink');
		if ($link.length === 0) {
			return false;
		}
		if ($('.my-budgets.udu__no-budget-popup').length > 0) {
			return false;
		}
        app.balloon.assignStableBalloon (
        	$link,
			'<div class="udu__popup-budget-link">' + $link.data('udu-popup') + '</div><div class="udu__loading-v1"></div>',
			{side:['bottom'], isWhiteMode: true}
		);

        $('#myBudgetsLink, #myBudgetsLink *').mousemove(
			function() {
				if (!$link.hasClass('_done')) {
                    $link.addClass('_done');
                    setTimeout(
                    	function() {
                            app.budgets.load($link);
						},
						400
					);
				}
			}
		)
	},
    load: function($link) {
        $.post(
            app.ajaxProcessURLs.process,
            {
                op: 'budget-list-info'
            },
            function(data) {
               var html = '<div class="udu__popup-budget-link">' + $link.data('udu-popup') + '</div><div class="udu__popup-budget-body _udu">' + data + '</div>';
               $('body').append('<div id="udu__budget-tmp" class="hidden"></div>');
               var $tmp = $('#udu__budget-tmp');
               $tmp.html(html);
               $tmp.find('.udu__popup-budget-link a').attr('href', $link.find('a').attr('href'));
               $tmp.find('.budget-comments').remove();
               $tmp.find('td.dateStart, td.dateEnd').each(
               		function() {
               			var $this = $(this);
               			$this.html(app.utils.date.formatDate($this.html()));
					}
			   );
               html = $tmp.html();
               $tmp.remove();
			   $link.data('udu-balloon-html', html);
               app.balloon.show($link, html, {noDelay: true, onlyIfVisible: true, isWhiteMode: true, isStable: true});
            }
        )
	}
};

app.fixIE11 = function(doAssign) {
    var isIE11 = false;
	try {
        isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
	} catch(e) {
		return false;
	}

	if (!isIE11) {
		return ;
	}

	if (doAssign) {
        $('.list-grid a').click(
        	function(e) {
        		e.preventDefault()
        	}
        );

        $('.list-grid li').click(
        	function() {
                app.setViewType($(this).hasClass('grid') ? 1 : 2);
			}
		);

		if ($('.udu-v-2').length === 0) {
			return false;
		}
		$(window).resize(
			function() {
               	app.fixIE11();
			}
		)
	}

	var $pLists = $('.product-list2');
	var $d1 = $('.d1');

	for (var i = 0; i < $pLists.length; i++) {
        $pLists[i].style.setProperty('width',  $d1.width() + "px",  "important");
	}

};

/**
 * Returns General JSON for Site
 * @param callBack
 * @return {{}}
 */
app.getAllJSON = function(callBack) {
	if (!window.admin || app._vars._getAllJSON_wasCalled) {
		var savedData = this.getLData('get-all-json', true);
		if (typeof savedData === 'object') {
			if (callBack) {
                callBack(savedData);
			}
            return savedData;
		}
	}
	app._vars._getAllJSON_wasCalled = true;
	this.getInfo('get-all-json', {json: true}, callBack);
	return {};
};

/**
 * Returns params from All JSON
 *
 * @param key
 * @returns {*}
 */
app.getAllJsonParamByKey = function(key) {
	var arr = app.getLData('get-all-json', true);
	if (arr[key]) {
		return arr[key];
	}
	return null;
};


/**
 * Processes JSON data
 */
app.processAllJSON = function() {
	this.getAllJSON(
		function(data) {
			if (typeof data !== 'object') {
                app.setLData('get-all-json', {__loaded: Date()}, true);
				return false;
			}
            data.__loaded = Date();
            app.setLData('get-all-json', data, true);
            if (data.udu__localStorage) {
            	app.setLData('udu-all-json-local-storage', data.udu__localStorage);
			}
			if (data.widgets) {
				for (var i in data.widgets) {
					if (data.widgets[i].status === 'Y') {
						app.getScript(
							['style-widgets.css', 'main.widgets.js'],
							function() {
								app.processWidgets(data);
							}
						);
						break;
					}
				}
			}
			var termStatus = data.uduDisplayTermsAndConditions;
			if (termStatus === 'Y' || (termStatus === 'L' && app._usrLogged)) {
				if (app.getLData('uduTermsAndCondsDone') !== 'Y') {
					app.utils.scripts.initWnd(
						function() {
							var params = {
								noCloseButton: true,
								onYesTitle: app.lang.selectWord('Accept', 'Accepter')
							};
							app.lang.getWord(
								'termsAndConditionsBody',
								function(str) {
									app.wnd.whiteDialog(
										str,
										function() {
											app.setLData('uduTermsAndCondsDone', 'Y');
										},
										params
									);
								}
							)
						}
					);
				}
			}
			 if (data.udu_displayPopupOnSiteEnter === 'Y' && app.getLData('udu_esPopShown', true) !== 'Y') {
				app.utils.scripts.initWnd(
					function() {
						var params = {};
						if (data.udu_displayPopupBottomButtons === 'N') {
							params.noButtons = true;
						}
						app.lang.getWord(
							'udu_displayPopupOnSiteEnterBody',
							function(str) {
								app.wnd.whiteDialog(
									str,
									function() {
										app.setLData('udu_esPopShown', 'Y', true);
									},
									params
								);
							}
						)
					}
				);
				} else if (app._usrLogged && data.udu_DisplayMarketingPopup === 'Y' && app.getLData('udu_marketingOptIn_' + app._vars.userId) === '') {
            	app.getInfo(
            		'marketingOptIn',
					{},
					function(data) {
            			try {
            				data = JSON.parse(data);
						} catch(e) {
            				return false;
						}
						if (data.marketingOptIn === '') {
							app.utils.scripts.initWnd(
								function() {
									app.getInfo(
										'udu_marketingPopupHtml',
										{

										},
										function(str) {
											app.wnd.alert(
												str,
												function(w) {
													//udu__marketingOptIn
													app.balloon.close(true);
													if ($('input[name=udu__wnd-input]:checked').length === 0) {
														app.balloon.showError(
															'.udu__marketingOptIn input',
															app.lang.selectWord(
																'Please select the value and try again.',
																'Veuillez sélectionner la valeur et réessayer.'
															)
														);
														w.effects.shake();
														return false;
													}
													var val = $('input[name=udu__wnd-input]:checked').val();
													app.getInfo('set-marketingOptIn',{val: val});
													app.setLData('udu_marketingOptIn' + app._vars.userId, val);
													w.destroy();
												},
												{
													onClose: function(w) {
														w.destroy();
													},
													onYesTitle: app.lang.selectWord('Submit', 'Soumettre')
												}
											);
										}
									)
								}
							);
						} else {
							app.setLData('udu_marketingOptIn', 'Y');
						}
					}
				);
			}

			var $listItems = $('.product-list2 .item');
			if (data.udu_quickOrderFunctionality == 'Y' && $listItems.length > 0) {
				app.utils.scripts.initQuickOrder();
			}

			if (data.udu_GDPR === 'Y' && (!app.cookies.readCookie('cookieconsent_status') || app.cookies.readCookie('cookieconsent_status') === 'revokable')) {
				app.getScript([
					'https://cdn.uducat.com/static/esb/js/app.gdpr.js',
					'https://cdn.uducat.com/static/esb/udu-css/css/cookieconsent.min.css',
				])
			}

			if (data.udu_prodListZoomType && $listItems.length > 0) {
				app.utils.scripts.initZoomer(
					function() {
						app.zoomer.makeForProductList(data.udu_prodListZoomType);
					}
				);
			}

			var slideMenuKey = 'udu_Display_Slide_Panel_' + app.getLang();
            if (data[slideMenuKey] !== 'N' && data[slideMenuKey] !== '') {
				app.getScript(
					['https://cdn.uducat.com/static/esb/udu-css/css/app.slideMenu.css', 'https://cdn.uducat.com/static/esb/js/app.slideMenu.js'],
					function() {
						app.slideMenu.init(data)
					}
				)
			} else if (app.slideMenu) {
				app.slideMenu.destroy();
			}

			if (data.displayDefaultLocationPopup === 'Y' && $('#contact-information').length === 1) {
            	app.utils.scripts.initDefLocation(
					function() {
						app.defLoc.init(data)
					}
				);
			}

			if (data.udu_addFocusStyles === 'Y') {
            	$('body').addClass('udu__acc-focused-enabled');
			}

			var udu_searchMode = data.udu_searchMode;
			if (udu_searchMode) {
				$('#advanced-search-form form, .top .div-search-field form')
					.each(
						function() {
							var $form = $(this);
							var $input = $form.find('input[name=mode]');
							if ($input.length === 1) {
								$input.val(udu_searchMode);
							} else {
                                $form.append('<input class="hidden" name="mode" value="' + udu_searchMode + '">');
							}
						}
					)
			}
		}
	);
};

/**
 * Corrects links URL in case we have different links for Mobile and PC version
 * @param {number} wd
 */
app.correctMobileLinks = function(wd) {
	if (!wd) {
        wd = $(window).width();
	}

	if (wd > 760) {
		var tp = 'pc';
	} else {
		tp = 'mobile';
	}

	if (this._vars.lastMobileLinksType !== tp) {
        this._vars.lastMobileLinksType = tp;
        $('a[data-udu-mob-url]:not([data-udu-mob-url=""])').each(
        	function() {
        		var $item = $(this);
        		if (!$item.data('udu-pc-url')) {
                    $item.data('udu-pc-url', $item.attr('href'));
				}
				if (tp === 'pc') {
        			$item.attr('href', $item.data('udu-pc-url'));
				} else {
                    $item.attr('href', $item.data('udu-mob-url'));
				}
			}
		)
	}
};

/**
 * Gets info by Specific Code
 * @param {number|string} opType
 * @param {{}} params
 * @param {function} callBack
 * @param {string} sessionStorage
 */
app.getInfo = function(opType, params, callBack, sessionStorage) {
	if (sessionStorage && !window.admin) {
		var data = app.getLData(sessionStorage, true);
		if (data && callBack) {
			callBack(data);
			return false;
		}
	}

	var that = this;
	var postData = {};
	for (var i = 1; i < arguments.length; i++) {
		if (typeof arguments[i] === 'function') {
            callBack = arguments[i];
		} else if (typeof arguments[i] === 'object') {
            postData = arguments[i];
		}
	}
    postData.op = opType;
	if (postData.json) {
		delete postData.json;
        postData.toJSON = true;
	}
	var method = 'POST';
    that.asAjax(true);
	if (opType === 'get-all-json') {
		method = 'GET';
	}
	if (params && params.method) {
		method = params.method;
	}

	var url = app.url.ajaxUrl(params._lang);

	$.ajax({
		url: url,
		data: postData,
		method: method,
		complete: function(data) {
			data = data.responseText;
			if (data.indexOf('<!--AJAX_MODE_MSG-->') > 0) {
				data = data.split('<!--AJAX_MODE_MSG-->')[1];
			}
            if (postData.toJSON) {
                try {
                    if (postData.op !== 'get-all-json') {
                        data = $.parseJSON(data);
                    } else {
                        var tmp = data.split(app._vars.uduDelim);
                        data = $.parseJSON(tmp[0]);
                        var data2 = $.parseJSON(tmp[1]);
                        for (var i in data2) {
                            data[i] = data2[i];
                        }
                    }
                } catch(e) {
                    that.asAjax(true);
                    $.ajax({
						url: String(document.location),
						data: postData,
                        method: method,
                        complete: function(res) {
                            res = res.responseText;
                            that.asAjax(false);
                            res = res.split('<!--AJAX_MODE_MSG-->');
                            if (res && res[1]) {
                                if (sessionStorage) {
                                    app.setLData(sessionStorage, res[1], true);
                                }
                                if (callBack) {
                                    if (postData.op !== 'get-all-json') {
                                        var json = $.parseJSON(res[1]);
                                    } else {
                                        var tmp = res[1].split(app._vars.uduDelim);
                                        json = $.parseJSON(tmp[0]);
                                        var data2 = $.parseJSON(tmp[1]);
                                        for (var i in data2) {
                                            json[i] = data2[i];
                                        }
                                    }
                                    callBack(json);
                                }
                            }
                        }
					});
                    return  false;
                }
            }
            if (callBack) {
                if (sessionStorage) {
                    app.setLData(sessionStorage, data, true);
                }
                callBack(data);
            }
        }
	});
};

/**
 * Returns store Attr Data
 * @param {string} attrName
 * @param {function} callBack
 */
app.getStorAttr = function(attrName, callBack) {
	app.getInfo('get-store-attribute', {attrName: attrName, method: 'GET'}, callBack);
};

/**
 * Sets images in Product Option List
 *
 * @param {int} val
 * @param {bool} noRecall
 * @returns {bool}
 */
app.setProductOptionsImage = function(val, noRecall) {
	var $fld = $('._ fieldset.options');
	if (app.prodInfo && app.prodInfo && app.prodInfo.optionsImages && app.prodInfo.optionsImages[val]) {
		var inf = app.prodInfo.optionsImages[val];
		var $img = $fld.find('.option-image img');
		$img.addClass('loading')
			.attr('src', inf.src);
		$fld.find('.option-image a')
			.attr('href', inf.srcOrig);
		$fld.addClass('with-images');

		return true;
	} else {
		$fld.removeClass('with-images');
		if (!noRecall) {
			app.setIniProductOptionsImage();
		}
		return false;
	}
};

/**
 * Sets Initial Image for Product Option List
 *
 * @returns {void}
 */
app.setIniProductOptionsImage = function() {
	$('._ fieldset.options select.mandatory, ._ fieldset.options select.optional').each(
		function() {
			var val = $(this).val();
			if (val) {
				var res = app.setProductOptionsImage(val, true);
				if (res) {
					return false;
				}
			}
		}
	);
};

/**
 * Changes product count on Product Detail page
 * @param {int} dir
 * @param {Object} item
 * @param {bool} controlButtonsOnly
 */
app.changeProdQuantity = function(dir, item, controlButtonsOnly) {
	if (!item) {
		var $item = $('#pr_form_id_quantity');
		var $btn  = $('button.btn-minus');
		var $btn2  = $('button.btn-plus');
	} else {
		var $div = $(item).closest('div');
		$item = $div.find('input');
		$btn  = $div.find('button.btn-minus');
		$btn2  = $div.find('button.btn-plus');
	}
    var maxCnt = 99999;
	var minCnt = 1;

	var step = Number($item.data('udu-step'));
	if (!step) {
        step = 1;
	}
	if (step && (dir === -1 || dir === 1)) {
		dir = step * dir;
	}

    if ($item.closest('ul.mult-list, div.submit.mult-ordering').length === 1 && !$item.hasClass('udu__mult-done')) {
        minCnt = 0;
        $item.data('udu-min-qty', 0).val(0);
        $item.addClass('udu__mult-done')
    }

	var reqMin = $item.data('udu-req-qty');
	if (Number(reqMin)) {
		if (dir > 0 && Number(reqMin) > Number($item.val())) {
            $item.val(reqMin - 1);
		} else if (dir < 0 && Number(reqMin) >= Number($item.val())) {
            $item.val(step);
		}
	}

	if (this._availableQuantity || this._availableQuantity === 0) {
		maxCnt = this._availableQuantity;
		if (Number($item.data('udu-max-qty')) >= 0) {
			maxCnt = Math.min($item.data('udu-max-qty'), maxCnt);
		}
		if ($item.attr('id') === 'pr_form_id_quantity' && app.prodInfo && app.prodInfo.maxQty) {
            maxCnt = Math.min(app.prodInfo.maxQty, maxCnt);
		}
        $item.data('udu-max-qty', maxCnt);
	} else if (Number($item.data('udu-max-qty')) >= 0) {
		maxCnt = $item.data('udu-max-qty');
	}

	if (Number($item.data('udu-min-qty')) >= 0) {
		minCnt = $item.data('udu-min-qty');
		if (minCnt > 0) {
			minCnt = app.money.trunc(minCnt, step);
		}
	}

	var cnt = Math.max(Number($item.val()) + Number(dir), Number(minCnt));

	if (cnt >= maxCnt) {
		$btn2.attr('disabled', true);
		clearTimeout(this.btnQuantTimeoutId);
		clearInterval(this.btnQuantIntervalId);
		cnt = maxCnt;
	} else {
		$btn2.attr('disabled', false);
	}
	if (cnt <= minCnt) {
		$btn.attr('disabled', true);
		clearTimeout(this.btnQuantTimeoutId);
		clearInterval(this.btnQuantIntervalId);
	} else {
		$btn.attr('disabled', false);
	}

	if (!controlButtonsOnly) {
		$item.val(cnt)
			.keyup();
	}
};

/**
 * Returns Message By Code
 *
 * @param {string} inCode
 * @param {bool} removeAfterReading
 * @returns {string}
 */
app.getMsg = function(inCode, removeAfterReading) {

	if (removeAfterReading) {
		var savedMessages = this.getLData('savedLangMessages_' + this.getLang(), true);
		delete savedMessages[inCode];
		this.setLData('savedLangMessages_' + this.getLang(), savedMessages, true);
	}

	if (this.messages[inCode]) {
		return this.messages[inCode];
	}

	return inCode;
};

/**
 * Decodes urlencode string from Server part
 *
 * @param {string} str
 * @returns {string}
 */
app.urlDecode = function(str) {
	return decodeURIComponent((str + '').replace(/\+/g, '%20'));
};

/**
 * Returns Original Product ID based in poroduct ID from URL
 * @param {String} prodId
 * @returns {Number}
 */
app.getOrigProdId = function(prodId) {
	if (Number(prodId)) {
		return prodId;
	}
	var res = String(prodId).match(/-(\d+)\.html/);
	if (res && res[1]) {
		return res[1];
	}
};

/**
 * Sets product Available Quantity
 *
 * @returns {bool}
 */
app.setProductAvailableQuantity = function() {
	if (app.prodInfo && app.prodInfo.multipleOrdering != 'N') {
		return false;
	}
	if (app.doBeforeSetAvailableQuantity) {
        var res = app.doBeforeSetAvailableQuantity();
        if (res === false) {
        	return false;
		}
	}

	var that = this;
	var arr = [];
	var $options = $(".product-options select, .product-options input:checked");
	if (!app.optionsWereInitialized) {
		var $allOptions = $(".product-options select, .product-options:checkbox");
		$allOptions.change(
			function() {
				app.setProductAvailableQuantity();
			}
		);
		app.optionsWereInitialized = true;
	}
	$options.each(
		function() {
			var val = $(this).val();
			if (val) {
                arr.push({name: "optionElementIds[]", val: val});
			}
		}
	);
	arr.push({name: 'productId', val: that.getOrigProdId($('#productId').val())});
	var $prodQnt = $('.product-quantity')
		.stop()
		.fadeTo(100, 0.01)
		.removeClass('udu__positive-inventory');

	var $prodQntAlt = $('.product-quantity-alternative');
	if ($prodQntAlt.length > 0) {
        $prodQntAlt.addClass('hidden');
	}
	if (!app.productAvailableQuantityUrl) {
		if (app.prodInfo) {
            app.productAvailableQuantityUrl = app.prodInfo.inventoryURL;
		}
	}
	$.ajax({
		url: this.formURL(app.productAvailableQuantityUrl, arr),
		success:function(data){
			var res = null;
			var allowBackorder = false;
			var $qnt = $('#pr_form_id_quantity').removeClass('allowBackorder');

			if (data && Number(data.code) === 200) {
				res = Math.max(data.data.quantity, 0);
                allowBackorder = data.data.allowBackorder;
			}

			if (data && data.data) {
				app.events.trigger('udu_INVENTORY-SET', {avlQnt: data.data.quantity, $maxQtyItem: null, $li: null});
			}

			if (!app.prodInfo.maxQty) {
                that._availableQuantity = 99999;
			} else {
                that._availableQuantity = app.prodInfo.maxQty;
			}
			that._vars.allowBackOrderAvailableQuantity = null;
			if (!allowBackorder && res !== null) {
                that._availableQuantity = res;
			}

			if (allowBackorder) {
				$qnt.addClass('allowBackorder');
                app.getScript(
                    'https://cdn.uducat.com/static/esb/js/main.prod.order.js',
					function() {

					}
				)
			}

            $qnt.data('udu-max-qty', that._availableQuantity);

			if (that.displayZeroQuantity === 'P') {
				$qnt.data('udu-max-qty-msg', '__uduMsg:_productAvailableQuantityMessage');
			}

			var $btn = $('.span-submit button');
			var $coverSpan = $('.span-submit #block-span:visible');
			$prodQnt.find('> span:not(.available-label):not(.udu__attr-inv-message)')
				.addClass('hidden');

            var $avlLabel = $prodQnt.find(' > span.available-label')
				.removeClass('udu__inv-not-exists');

			var $zeroMsg = $('#udu_availableQntZeroMessage')
				.removeClass('shown');

			if (app.doAfterAvailableQtyReceived) {
                app.doAfterAvailableQtyReceived(res);
			}

			var $lb = $('.product-inventory.udu__with-custom-msg .available-label');
			if ($lb.length > 0) {
				if (!$lb.hasClass('_done')) {
					$lb.addClass('_done');
					$lb.data('in-stock-msg', $lb.html());
					if (!$lb.data('out-stock-msg')) {
						$lb.data('out-stock-msg', $lb.html())
					}
				}
				if (!res) {
					res = 0;
				}
				if (res > 0) {
					$lb.closest('div.product-inventory').addClass('udu__label-in-stock');
				} else {
					$lb.closest('div.product-inventory').removeClass('udu__label-in-stock');
				}

				var e = {
					$lb: $lb,
					msg: $lb.data(res > 0 ? 'in-stock-msg' : 'out-stock-msg'),
					inv: res
				};

				app.events.trigger(
					'udu-IN-STOCK-MSG-BEFORE-SET',
					e
				);

				$lb.html(e.msg.replace(/%CNT%/g, res));
			}

			if (Number(res) || (res === 0 && $('.udu__attr-inv-message').length > 0)) {
				$prodQnt.addClass('udu__positive-inventory')
					.find('#available-quantity')
					.removeClass('hidden')
					.html(res);

				if ($coverSpan.length === 0) {
					$btn.attr('disabled', false);
				}
				if (app.doBeforeAvailableQtyDisplay) {
                    var r = app.doBeforeAvailableQtyDisplay($prodQnt, res);
				} else {
					r = true;
				}
				if (r !== false) {
                    $prodQnt
                        .removeClass('hidden')
                        .stop()
                        .fadeTo(300, 1);
				}

				if (that.displayZeroQuantity === 'Y2' && res > 0) {
					$prodQnt.addClass('hidden');
				}
			} else if (res === 0 && (that.displayZeroQuantity !== 'N' || that.disableAddButtonForZeroQnt !== 'N')) {
				if (that.displayZeroQuantity !== 'N') {
					if ($prodQntAlt.length === 0) {
                        $prodQnt.find('> .product-sold-out')
                            .removeClass('hidden');
                        $avlLabel.addClass('udu__inv-not-exists');
                        $prodQnt.removeClass('hidden')
                            .stop()
                            .fadeTo(300, 1);

					} else {
                        $prodQntAlt.removeClass('hidden');
					}
				} else {
					$prodQnt.addClass('hidden');
				}
				if (that.displayZeroQuantity === 'P' && res === 0) {
					$prodQnt.addClass('hidden');
				}
				if (that.disableAddButtonForZeroQnt !== 'N' && !allowBackorder) {
					$btn.attr('disabled', true);
					$zeroMsg.addClass('shown');
				} else if (allowBackorder) {
                    if ($coverSpan.length === 0) {
                        $btn.attr('disabled', false);
                    }
				}
			} else {
				$prodQnt.addClass('hidden');
				if ($coverSpan.length === 0) {
					$btn.attr('disabled', false);
				}
			}

			if (app.prodInfo.hideInventory) {
				$prodQnt.addClass('hidden');
			}

			if (app.prodInfo.hideOutOfStock) {
				$('.available-qnt-zero').removeClass('shown');
			}
            that.changeProdQuantity(0);
		}
	});

	return true;
};

/**
 * Checks SVG Browser Compatibility and Show/Hide SVG editor
 *
 * @returns {boolean}
 */
app.checkCompatibility = function() {
	if ($('div.attachments-submit').length === 0) {
		return false;
	}
	var ieVersion = app.getInternetExplorerVersion();

	if (ieVersion < 0 || ieVersion >= 10) {
		$('._ .attachments-submit').fadeIn();
	} else {
		$('._ .attachments-error').fadeIn(0);
	}

	return true;
};

/**
 * Returns IE Version
 *
 * @returns {number}
 */
app.getInternetExplorerVersion = function() {
	var rv = -1;
	if (navigator.appName == 'Microsoft Internet Explorer') {
		var ua = navigator.userAgent;
		var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
		if (re.exec(ua) != null)
			rv = parseFloat( RegExp.$1 );
	}
	return rv;
};

/**
 * Sets Product List View Type - List/Grid
 *
 * @param {number} inType
 * @returns {void}
 */
app.setViewType = function(inType) {
	if (app.changeViewTypeProcess) {
		return;
	}
	app.changeViewTypeProcess = true;
	var className = inType == 1 ? 'grid' : 'line';
	$('.list-grid li').removeClass('selected');
	$('.list-grid .' + className).addClass('selected');
	app.cookies.createCookie('categoryViewType', className);
	$('.product-list2')
		.fadeTo(
		300,
		0,
		function() {
			app.changeViewTypeProcess = false;
			$(this)
				.removeClass('grid line')
				.addClass(className)
				.fadeTo(300, 1);

			if (app.getProdListPriceTables) {
				app.getProdListPriceTables();
			}
			if (className === 'grid' && app.correctProductNameHeight) {
				app.correctProductNameHeight();
			}
		}
	)
};

app.cookies = {
	_vars: {

	},
    /**
     * Creates Cookies for template
     *
     * @param {string} name
     * @param {string} value
     * @param {number} days
     * @returns {app.cookies}
     */
    createCookie: function(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		if (app.url.isHttps()) {
            document.cookie = name + "=" + value + expires + "; path=/;SameSite=None;secure";
		} else {
            document.cookie = name + "=" + value + expires + "; path=/";
		}
		return this;
	},
    /**
     * Reads cookie
     *
     * @param {string} name
     * @returns {string}
     */
    readCookie: function(name) {
		var matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	},
	isActive: function() {
		app.cookies.createCookie('udu_CookieActivated', 'Y');
		var res = app.cookies.readCookie('udu_CookieActivated');
		app.cookies.deleteCookie('udu_CookieActivated');
		return res === 'Y';
	},
	/**
	 * Deletes cookie by name
	 * @param {string} name
	 * @returns {app}
	 */
	deleteCookie: function(name) {
    	if (!name) {
    		return false;
		}
		document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    	return app;
	}
};

//todo: remove app.createCookie and app.readCookie and use app.cookies....

/**
 * Creates Cookies for template
 *
 * @param {string} name
 * @param {string} value
 * @param {number} days
 * @returns {void}
 */
app.createCookie = function(name, value, days) {
	app.cookies.createCookie(name, value, days);
};

/**
 * Reads cookie
 *
 * @param {string} name
 * @returns {string}
 */
app.readCookie = function(name) {
	return app.cookies.readCookie(name);
};

/**
 * Sets page size - for Product List (when user change it in DropDown)
 *
 * @param {number} pgSize
 * @returns {void}
 */
app.setPageSize = function(pgSize) {
	app.setUrlParams(
		{
			pg_size: pgSize
		}
	)
};

/**
 * Makes URL by URL and params
 *
 * @param url
 * @param params
 * @returns {*}
 */
app.makeURL = function(url, params) {
    if (!url) {
        url = String(document.location);
        return url;
    }

    if (Array.isArray(params)) {
        var tmp = {};
        for (var i = 0; i < params.length; i++) {
            var info = params[i];
            tmp[String(params[i].name)] = params[i].val;
        }
        params = tmp;
    }

    var urlArr = url.split('#')[0].split('?');
    var arr = [];
    var get = '';

    if (urlArr[1]) {
        arr = urlArr[1].split('&');
    }
    for (i = 0; i < arr.length; i++) {
        tmp = arr[i].split('=');
        if (!params.hasOwnProperty(tmp[0]) && tmp[0]) {
            params[tmp[0]] = tmp[1];
        }
    }
    for (i in params) {
        if (params[i] !== '') {
            get += i + '=' + params[i] + '&';
        }
    }
    if (get) {
        return urlArr[0] + '?' + get.slice(0, -1);
    } else {
        return urlArr[0];
    }
};

/**
 * Sets URL params as Get String and assigns it to document.location
 *
 * @param {{}} params
 * @returns {void}
 */
app.setUrlParams = function(params) {
	var urlArr = String(document.location).split('#')[0].split('?');
	var arr = [];
	var get = '';

	if (urlArr[1]) {
		arr = urlArr[1].split('&');
	}

	for (var i = 0; i < arr.length; i++) {
		var tmp = arr[i].split('=');
		if (!params.hasOwnProperty(tmp[0]) && tmp[0]) {
			params[tmp[0]] = tmp[1];
		}
	}
	for (i in params) {
		if (params[i] !== '') {
			get += i + '=' + params[i] + '&';
		}
	}

	if (get && get.slice(-1) === '&') {
		get = get.slice(0, -1);
	}

	if (get) {
		document.location = urlArr[0] + '?' + get;
	} else {
		document.location = urlArr[0];
	}
};

/**
 * Shows Social buttons and assigns mouseover and mouseout for links there
 *
 * @param {{}} animateParams
 * @returns {void}
 */
app.makeSocialButtons = function(animateParams) {
	if (animateParams) {
		var selector = ".social-buttons div.soc-wrapper";
		if (animateParams.animateSelector) {
			selector = animateParams.animateSelector;
			delete animateParams.animateSelector;
		}
		$(selector).animate(animateParams, 1000);
	}
	$("._ .social-buttons a")
		.mouseover (
		function() {
			$("._ .social-buttons a").not(this).stop().fadeTo(400, 0.5);
			$(this).stop().fadeTo(200, 1);
		}
	).mouseout(
		function() {
			$("._ .social-buttons a").stop().fadeTo(800, 1);
		}
	)
};

/**
 * Defines if we work in mobile mode
 *
 * @returns {boolean}
 */
app.isMobile = function() {
	if (window.appMobile && appMobile.isMobileMode && appMobile.isMobileMode()) {
		return true;
	}
	return false;
};

/**
 * Initialize Floating Header
 *
 * @returns {void}
 */
app.initFloatingHeader = function(params) {
	if (this.isMobile()) {
		return;
	}
	var that = this;
	var h = $(params.floatSelector).height();

	if (!params.noTopFixDiv) {
		$('._').before('<div style="height:' + h + 'px" class="top-fix"></div>');
	}
	var fixTop = h - params.floatFix;
	if (fixTop) {
		this.fixTop = fixTop;
	}

	$("<style type='text/css'> ._ .fix {top:-" + this.fixTop + "px}</style>")
		.appendTo("head");

	$(window).scroll(
		function(){
			that.floatingHeader(params.floatParams);
		}
	);
	this.floatingHeader(params.floatParams);
};



/**
 * Controls scrolling to make fixed header
 *
 * @returns {void}
 */
app.floatingHeader = function(params) {
	var $top = $("._ > .top");
	var scroll = $(document).scrollTop();
	if (!app._vars._floatingHeaderDone) {
		app._vars._floatingHeaderDone = true;
		app.utils.css.cssVar('--fixTop', app.fixTop + 'px');
	}
	if(scroll > app.fixTop) {
		if (!$top.hasClass('fix')) {
			$top.addClass('fix');
            app.balloon.repositionAll(true);
			$('body').addClass('udu__body-fixed');
			$(".top-fix").addClass('visible');
			if (params && params.onSetFix) {
				params.onSetFix(scroll);
			}
			if (app.balloon) {
                app.balloon.close();
			}
			if (app.uMenu) {
				app.uMenu.sync();
			}
		}
	} else {
		if ($top.hasClass('fix')) {
			$top.removeClass('fix');
            app.balloon.repositionAll(true);
            $('body').removeClass('udu__body-fixed');
			$(".top-fix").removeClass('visible');
			if (params && params.onUnSetFix) {
				params.onUnSetFix(scroll);
			}
			if (app.uMenu) {
				app.uMenu.sync();
			}
		}
	}
	if (params && params.onScroll) {
		params.onScroll(scroll);
	}
};

/**
 * Sets URL in cookies for last Product List Page and Blog List
 *
 * @returns {void}
 */
app.setBackUrls = function() {
	if ($('body').hasClass('blog-list')) {
		app.cookies.createCookie('blogBackUrl', document.location);
	}
};

/**
 * Selects SVG Assignment in Default Mode
 *
 * @param {number} idx
 * @returns {void}
 */
app.setSelectedBadge = function(idx) {
	var itemId = 'attachment-' + idx;
	$('#' + itemId)
		.closest('ul')
		.find('> li')
		.removeClass('not-selected')
		.not('.attachment-comment')
		.each(function() {
			var wnd = $(this).find('iframe')[0].contentWindow;
			if (this.id !== itemId) {
				$(this).addClass('unset');

				$('input[name^="attachments[' + this.id.split('-')[1] + ']"]')
					.addClass('for-delete');

				wnd.$('svg')
					.closest('li')
					.addClass('unset');

			} else {
				$(this).removeClass('unset');
				wnd.$('div.svg-wrapper').html(
						$('input[name^="attachments[' + this.id.split('-')[1] + ']"]')
							.removeClass('for-delete')
							.val()
					)
					.closest('li')
					.removeClass('unset');
				if (wnd.svgPrv) {
					wnd.svgPrv.correctViewBox();
				}
			}
		});
	var $policy = $('.policy');
	if ($('li.li-attachment:not(.unset)').length > 0) {
		$policy.removeClass('hidden');
	} else if ($('.udu__svg-required').length === 0) {
        $policy.addClass('hidden');
	}
};

/**
 * Attaches events to work with SVG Editor in Default Mode
 *
 * @returns {void}
 */
app.attachSvgEvents = function() {
	$('body')
		.on('change', 'input[name^="attachments"]', function() {
			var idx = $(this).attr('name').match(/\d+/)[0];
			app.setSelectedBadge(idx);
			app.enableProdSubmit();
		})
		.on('unrender', '.attachment-wigdet-customizable-svg', function() {
			app.blockWindow(false);
		});
};

/**
 * Calls Attachment Editor - in Edit Mode
 *
 * @returns {void}
 */
app.callAttachmentEditor = function(idx) {
	app.blockWindow(
		true,
		true,
		function() {
			window.location.hash = $('#attachment-' + idx).find('.attachment-link a').click().attr('href');
			$('#div-block').css('z-index', 99);
		}
	);
};

/**
 * Disables / Enables submit button for Product Detail Page
 *
 * @param {Object} inItem
 * @returns {void}
 */
app.enableSubmit = function(inItem) {
	if (inItem.checked) {
		$('#submit-button').removeAttr('disabled');
	} else {
		$('#submit-button').attr('disabled', 'disabled')
	}
};

/**
 * Covers submit button with span, to display message when user clicks on it
 *
 * @returns {void}
 */
app.addCoverSpan = function() {
	if (!$(".span-submit button").is(":disabled")) {
		return;
	}
	$('<span id="block-span"></span>')
		.click(
		function() {
			var $li = $('li.not-selected');
			if ($li.length >= 1) {
				app.alert(app.getMsg('_Message_No_SVG_Selected'));
			} else {
				app.alert(app.messages.disabledButtonMessages);
				app.alertMsg('_Product_Disabled_Message')
			}
		}
	).appendTo('.span-submit');
};

/**
 * Removes or Add block span for submit button
 *
 * @returns {void}
 */
app.enableProdSubmit = function() {
	var svgReqDone = true;
	$('li.not-selected').each(
		function() {
			var $ul = $(this).closest('ul');
			var $li = $ul.find('li.not-selected');
			var $liReqs = $ul.find('.udu__svg-required');
			if ($li.length > 0 && $liReqs.length > 0) {
				svgReqDone = false;
			}
		}
	);

	if (!svgReqDone) {
		$('div.not-completed').fadeIn();
	} else {
		$('div.not-completed').fadeOut();
	}

	var $agree = $('#Attachment_Agreed');

	if (!svgReqDone || (!$agree.prop('checked') && $agree.is(':visible'))) {
		$('.attachments-submit .submit button').attr('disabled', 'disabled');
		$('#block-span').show();
	} else {
		$('.attachments-submit .submit button').removeAttr('disabled');
		$('#block-span').hide();
	}
};

/**
 * Assigns over processing for Product List (when mouse over - it adds .over class to Prod Item)
 *
 * @param {bool} createListZoom
 * @returns {void}
 */
app.assignProductOver = function(createListZoom) {
	var $productList2 = $('._ .product-list2');
	if ($productList2.length === 0) {
		return;
	}
	$productList2.find("div.item").mouseover(
		function() {
			$(this).addClass('over');
		}
	).mouseleave(
		function() {
			$(this).removeClass('over');
		}
	);

	$productList2.find('.sku').each(
		function() {
			$(this).attr('title', $(this).html());
		}
	);
	if (window.appMobile && appMobile.isMobileMode && appMobile.isMobileMode()) {
		return;
	}

	if ($.fn.fancybox) {
		if (createListZoom) {
			$('<a class="list-zoom" href="#"></a>')
				.appendTo('._ .product-list2 div.item .image');
			$('.list-zoom').each(
				function() {
					$(this)
						.attr(
							'href',
							$(this).closest('.item').find('.image img').data('original-image')
						)
					;
				}
			);
		}
		if (!app._vars.noFancyForQuickView) {
			$('a.list-zoom, a.quick-view').fancybox({
				'transitionIn': 'elastic',
				'transitionOut': 'elastic'
			});
		} else {
			$('a.list-zoom').fancybox({
				'transitionIn': 'elastic',
				'transitionOut': 'elastic'
			});
		}
	}
};

/**
 * Generates UNIQUE KEY
 *
 * @returns {string}
 */
app.guid = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

/**
 * Returns current language fr/en
 *
 * @returns {string}
 */
app.getLang = function() {
	if (this._currentLang) {
		return this._currentLang;
	}
	return String(document.location).indexOf('/fr/') > 0 ? 'fr' : 'en';
};

/**
 * Adds checkboxes for register form to use it for copying functionality
 *
 * @returns {boolean}
 */
app.addRegisterCheckBoxes = function(onlyIfWeHaveRedirect) {
	if (onlyIfWeHaveRedirect && !app.url.needToRedirectAfterLogin()) {
		return false;
	}
	if (this._vars.addRegisterCheckBoxes__done) {
		return false;
	}
    this._vars.addRegisterCheckBoxes__done = true;
	var $billInfo = $('#billing-information');
	if ($billInfo.length === 0 || $billInfo.find('input:enabled').length === 0) {
        $('form.user-register').addClass('_done');
		return false;
	}
	app.getScript(
		'https://cdn.uducat.com/static/esb/js/main.register.func.js',
		function() {
            app._addRegisterCheckBoxes();
		}
	);
	return true;
};

/**
 * Applies Product Attributes to list
 */
app.applyProdListAttrs = function() {
	var $prodList = $('.product-list2, .ul-prod-list-carousel, .udu__product-grid');
	if ($prodList.length === 0 && !app.prodInfo) {
		return false;
	}
	if (app._vars.applyProdListAttrs_wasCalled) {
		return false;
	}
	app._vars.applyProdListAttrs_wasCalled = true;
    app.getScript(
        'https://cdn.uducat.com/static/esb/js/main.prod.list.attrs.js',
        function() {
            app.prodListAttrs.applyAttrs();
        }
    );
};

/**
 *  FE Reports for users
 *
 * @type {}
 */
app.reports = {
    makeReport: function(repType) {
        app.reports.makeHtml(repType);
    },
    makeHtml: function(repType) {
        app.blockWindow(true, true);
        app.getScript(
            ['https://cdn.uducat.com/static/esb/js/main.html-reports.js', 'https://cdn.uducat.com/static/esb/udu-css/css/main.html-reports.css'],
            function() {
                app.htmlReports.print(repType)
            }
        );
    }
};

/**
 * Initialize Tree (left menu)
 * @param {{}} options
 * @returns {void}
 */
app.initializeTree = function(options) {
	if (!options) {
		options = {};
	}
	var $a = $("._ .main div.li-block li a");
	this.setSelectedLink();
	var html = "<div class=\"node\"></div>";
	if (!this.treeOptions) {
		this.treeOptions = {};
	}
	if (this.treeOptions.colorizedNode) {
		html = "<div class=\"node\"><div></div></div>";
		$a.append('<span class="li-dot"></span>');
	}
	$a.each (
		function() {
			var $this = $(this);
			var $li = $(this).closest('li');
			var id = $this.attr('id');
			if (!id) {
				if ($li.attr('id')) {
					$this.attr('id', '_a-' + $li.attr('id'));
				}
			}
			var arr = $li.find('li');
			if (arr.length > 0) {
				$(this).addClass('tree-node');
				$(html)
					.appendTo(this)
					.bind(
					'mousedown',
					function() {
						var $this = $(this);
						if (!$this.hasClass('opened')) {
                            $this.addClass('opened');
                            $this.closest('a.tree-node').addClass('a-open');
                            $this.closest('li')
								.addClass('opened')
								.find('> ul')
								.hide()
								.slideDown();
						} else {
                            $this.removeClass('opened');
                            $this.closest('a.tree-node').removeClass('a-open');
                            $this.closest('li')
								.find('> ul')
								.slideUp();
						}

						app.saveTreeState();
                        app.accessibilty.fixLefMenuExpand();
						return false;
					}
				).bind(
					'click',
					function(e) {
						e = e || window.event;
						if (e.stopPropagation) {e.stopPropagation()} else {e.returnValue = false}
						e.cancelBubble = true;
						return false;
					}
				)
			}
			if ($(this).closest('li').hasClass('selected')) {
				$(this).addClass('selected');
				var tmp = $(this).parents('li');
				tmp.addClass('opened');
				while (tmp.length > 0) {
					$(tmp[0]).find('>div>a').find('div').addClass('opened');
					tmp = $(tmp[0]).parents('li');
					tmp.addClass('opened');
				}
			}
		}
	);

	if (!options.noRestore) {
        var ids = app.getLData('openedMenuItems');
        if (!ids) {
            if ($('li.level0 > div > .tree-node .node.opened').length === 0) {
                $('li.level0 > div > .tree-node .node').mousedown();
            }
        } else {
            ids.forEach(
                function (id) {
                    var $node =  $('#' + id + ' .node:not(.opened)');
                    if ($node.length > 0) {
                        $node.mousedown()
                    }
                }
            )
        }
	}

	if (options.blockNodedTreeLink) {
        $('a.tree-node').click(
        	function(e){
        		e.preventDefault();
        		$(this).find('.node').mousedown()
        	}
		);
	}
};

/**
 * Saves Tree State to open
 */
app.saveTreeState = function() {
	var arr = [];
    $('.li-block .node.opened').each(
    	function () {
			var id = $(this).closest('a').attr('id');
			if (id) {
				arr.push(id);
			}
        }
    );
    app.setLData('openedMenuItems', arr);
};

/**
 * Sets selected class to menu links (top and left parts)
 *
 * @param {bool} withoutGet
 * @returns {void}
 */
app.setSelectedLink = function(withoutGet) {
	var url = String(document.location);

	if (withoutGet) {
		url = url.split('?')[0];
	}

	var ln  = 0;
	var lst = null;
	$("._ .main div.li-block li a, ._ .menu li a").each (
		function() {
            var $this = $(this);
            var href = $this.attr('href');
			if (url.indexOf(href) >= 0 && String(href).length > ln) {
				ln = String(href).length;
				if (lst) {
					$(lst).removeClass('selected')
						.closest('li')
						.removeClass('selected');
				}
				lst = this;
                $this.addClass('selected')
					.closest('li')
					.addClass('selected');
			}
		}
	);
	if (!withoutGet && $("._ .main div.li-block li.selected a, ._ .menu li.selected").length === 0) {
		app.setSelectedLink(true);
		return ;
	}

    if ($("._ .main div.li-block li.selected, ._ .menu li.selected ").length === 0) {
        $("._ .menu li a.my-home").closest('li').addClass('selected');
    }

    if (app.focuser) {
		app.focuser.focus();
	}

	var $breadCrumb = $('.bread-crumb:not(.built):not(.prod)');
	if ($breadCrumb.length > 0 && !$breadCrumb.hasClass('built')) {
		var html = '';
		var $a = $('.li-block .level0 a.selected:not(.all-products)');
		if ($a.length === 0) {
			return;
		}
		html += ' / <span data-udu-href="' + $a.attr('href') + '">' + $a.html() + '</span>';
		var $ul = $a.closest('ul');
		while ($ul.length > 0) {
			$a = $ul.closest('li').find('> div a');
			if ($a.length === 0) {
				break;
			}
			html = ' / <a href="' + $a.attr('href') + '">' + $a.html().split('<div')[0] + '</a>' + html;
			$ul = $a.closest('ul');
		}

        html = '<a class="bread-prods">' + this.lang.selectWord('Products', 'Produits') + '</a>' + html;

		$breadCrumb.html(html).addClass('built').find('div, .li-dot').remove();
		var that = this;
		this.getURL(
			'category',
			function(url) {
				$breadCrumb.find('.bread-prods').attr('href', url);
				var savedCrumbs = that.getLData('udu-saved-crumbs');
				if (!savedCrumbs) {
					savedCrumbs = {};
				}
				savedCrumbs[String(document.location)] = $breadCrumb.html();
				that.setLData('udu-saved-crumbs', savedCrumbs);
			}
		)
	}

};

/**
 * Assign additional processing to input item - to enter Numbers only
 *
 * @returns {void}
 */
app.assignNumberControl = function() {
	var $inputs = $("input.number:not(._done), td.quantity input:not(._done)").addClass('_done');
    $inputs.bind('keyup',
		function() {
			var $this = $(this);
			if ($this.removeClass('is-filled').val() === '') {
				$this.val('');
				return ;
			}
			var val = this.value;
			if (!Number(val) || Number(val) < 0) {
				$this.val(0);
			} else {
				if ($this.hasClass('money')) {
					if (String(val).slice(-1) !== '.' && Number(val)) {
						var rVal = Math.round(val * 100) / 100;
						if (parseFloat(rVal) !== parseFloat(val)) {
							val = rVal;
							$this.val(val);
						}
					}
                    if (app.money.formatMoneyForInput(val) !== app.money.formatMoneyForInput(parseFloat(val))) {
                        $this.val(parseFloat(val));
                    }
				} else {
                    if (val !== parseInt(val)) {
                        $this.val(parseInt(val));
                    }
				}
			}
			if (Number($this.val())) {
				$this.addClass('is-filled');
			}
		}
	).change(
        function() {
            app.utils.validator.checkNumberItem(this);
        }
	).blur(
		function() {
			app.utils.validator.checkNumberItem(this);
		}
	).css('text-align', 'center');

    $inputs.each(
    	function() {
    		var $this = $(this);
    		var maxLength = $this.attr('maxlength');
    		if (!maxLength) {
                $this.attr('maxlength', 6);
			}
		}
	)
};

/**
 * Misc utils for app item
 */
app.utils = {
	setTimeout: function(callBack, timeOut, id) {
		if (!id) {
			id = '__generalTimeOut';
		}
		app._vars._timerIds = app._vars._timerIds || {};
		clearTimeout(app._vars._timerIds[id]);
		app._vars._timerIds[id] = setTimeout(
			function () {
				callBack();
			},
			timeOut
		);
	},
	copy2Clipboard: function(str) {
		var el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	},
	getMaxNumber: function(selector, callBack) {
		var res = 0;
		var $maxItem = null;
		$(selector).each(
			function() {
				var num = app.money.parse($(this).html());
				if (Number(num) && Number(num) > Number(res)) {
                    res = num;
                    $maxItem = $(this);
				}
			}
		);
		if (callBack) {
            callBack(res, $maxItem);
		}
		return res;
	},
	getBox: function(selector) {
		var $item = $(selector);
		var $doc = $(document);

		if ($item.length === 0) {
			return null;
		}

		var tmp = $item[0].getBoundingClientRect();

		var res = {
			top: tmp.top,
			bottom: tmp.bottom,
			left: tmp.left,
			right: tmp.right,
			height: tmp.height,
			width: tmp.width,
			x: tmp.x,
			y: tmp.y,
			docX: tmp.x + parseInt($doc.scrollLeft()),
			docY: tmp.y + parseInt($doc.scrollTop())
		};
	},
	setStyle: function($item, prop, val, params) {
		if (typeof params === 'boolean') {
			params = {important: true};
		}
		params = params || {};

		var pxer = {
			'margin-top': true
		};

		if (pxer[prop] && Number(val)) {
			val = val + 'px';
		}

		$($item).each(
			function() {
				var $this = $(this);
				if (params.important) {
					$this[0].style.setProperty(prop, val, 'important');
				} else {
					$this[0].style.setProperty(prop, val);
				}
			}
		)
	},
	getHeight: function(selector, params) {
		params = params || {};
		var res = 0;
		$(selector).each(
			function() {
				var $this = $(this);
				res += $this.outerHeight();
			}
		);
		if (params.cor) {
			res += params.cor;
		}

		return res;
	},
	getMaxHeight: function(selector, callBack) {
        var res = 0;
        var $maxItem = null;
        $(selector).each(
            function() {
                var num = Number($(this).height());
                if (num > Number(res)) {
                    res = num;
                    $maxItem = $(this);
                }
            }
        );
        if (callBack) {
            callBack(res, $maxItem);
        }
        return res;
    },
	disableEmptyLinks: function() {
        $('._').find('a:not([href]), a[href=""]').addClass('udu__empty-link').click(
        	function(e) {
        		e.preventDefault();
			}
		)
	},
	initBalloonNotification: function() {
		var $myCards = $('._ .udu-my-cards span');
		if ($myCards.length === 1) {
            app.balloon.showInfoByCode(
                $myCards,
                '_Click_Here_To_See',
                {
                    side: ['left'],
                    replace: {
                        '%HTML%': $myCards.html().split('(')[0]
                    }
                }
            );
		}
	},
    isItemVisible: function(elm, yDelta, evalType) {
		evalType = evalType || "visible";

        yDelta = yDelta || 0;

		var vpH = $(window).height(), // Viewport Height
			st = $(window).scrollTop(), // Scroll Top
			y = $(elm).offset().top,
			elementHeight = $(elm).height();

		if (evalType === "visible") {
			return (y < (vpH + st)) && (y - yDelta > (st - elementHeight));
        }
		if (evalType === "above") {
            return (y < (vpH + st));
		}
	},
	initQuantityButtons: function() {
        if ($('button.btn-quant:not(._done)').addClass('_done').click(
                function() {
                    var that = this;
                    var dir = 1;
                    if ($(this).hasClass('btn-minus')) {
                        dir = -1;
                    }
                    app.changeProdQuantity(dir, that);
                }
            ).mousedown(
                function() {
                    var btn = this;
                    var that = this;
                    clearTimeout(app.btnQuantTimeoutId);
                    clearInterval(app.btnQuantIntervalId);
                    app.btnQuantTimeoutId = setTimeout(
                        function() {
                            app.btnQuantIntervalId = setInterval(
                                function() {
                                    var dir = 1;
                                    if ($(btn).hasClass('btn-minus')) {
                                        dir = -1;
                                    }
                                    app.changeProdQuantity(dir, that);
                                },
                                100
                            )
                        },
                        300
                    )
                }
            ).length > 0) {
            $('#pr_form_id_quantity').keydown(
                function (e) {
                    if (e.which === 38){
                        $('.submit  .btn-plus').click();
                        e.preventDefault();
                    } else if (e.which === 40) {
                        $('.submit  .btn-minus').click();
                        e.preventDefault();
                    }
                    setTimeout(
                        function() {
                            app.changeProdQuantity(0, false, true);
                        },
                        10
                    );
                }
            )
        }
	},
	date: {
		formatDateInTDs: function(selector) {
			$(selector).each(
				function() {
					var $this = $(this);
					$this.html(app.utils.date.formatDate($this.html()));
				}
			)
		},
		formatTime: function(str) {
			if (!str) {
				return '';
			}
            str = str.split(' ');
            return str.slice(-1)[0];
		},
		datesDiffInDays: function(dateFrom, dateTo) {
            var date1 = new Date(dateFrom);
            if (dateTo) {
                var date2 = new Date(dateTo);
			} else {
                date2 = new Date();
			}
            var diffTime = Math.abs(date2 - date1);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		},
        formatDate: function (str) {
			if (!str) {
				return '';
			}
			var tmp = String(str).match(/\d+/g);
			if (!tmp || tmp.length !== 3) {
				return str;
			}

            if (tmp[0].length !== 4) {
                return str;
            }
            return tmp[2] + '-' + tmp[1] + '-' + tmp[0];
        },
		curDate: function() {
			var dt = new Date();
            var mm = dt.getMonth() + 1;
            var dd = dt.getDate();
            return [dt.getFullYear(),
                (mm > 9 ? '' : '0') + mm,
                (dd > 9 ? '' : '0') + dd
            ].join('-');
		},
        getToday: function () {
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!

            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return today = dd + '-' + mm + '-' + yyyy;
        }
	},
	validator: {
        checkNumberItem:  function(item) {
            var $this = $(item);
            var step = Number($this.data('udu-step'));
            if (!step) {
                step = 1;
            }

            if (step > 1) {
            	var stepVal = app.money.round($this.val(), step);
            	if (Number(stepVal) === 0) {
            		stepVal = step;
				}
                if (Number(stepVal) !== Number($this.val()) && $this.val() !== '' && Number($this.val()) !== 0) {
                    $this.val(stepVal);
                    app.doFlicker($this);
                    app.balloon.showInfoByCode(
                    	$this,
						'_No_Valid_Multiple_Msg',
                        {
                        	side: ['top', 'right', 'bottom', 'left'],
                            multipleMode: true,
							replace: {
                        		'%STEP%': step
							}
                        }
					);
				}
                if ($this.data('udu-min-qty')) {
                    $this.data('udu-min-qty', app.money.round($this.data('udu-min-qty'), step))
				}
                if ($this.data('udu-req-qty')) {
                    $this.data('udu-req-qty', app.money.round($this.data('udu-req-qty'), step))
                }
                if ($this.data('udu-max-qty')) {
                    $this.data('udu-max-qty', app.money.trunc($this.data('udu-max-qty'), step))
                }
			}

            if ($this.hasClass('money')) {
            	$this.val(app.money.formatMoneyForInput($this.val()));
			}
            if ($this.data('udu-min-qty') && (Number($this.val()) < Number($this.data('udu-min-qty')))) {
                app.doFlicker($this);
                var minMsg = $this.data('udu-min-qty-msg');
                if (minMsg) {
                    app.alert(minMsg.replace('%MIN%', $this.data('udu-min-qty')).replace('%QTY%', $this.val()));
                }
                if (!$this.data('udu-dont-change-min-qty')) {
                    $this.val($this.data('udu-min-qty'));
                }
            }
            var reqQty = $this.data('udu-req-qty');
            if (Number(reqQty) && Number($this.val()) > 0 && Number(reqQty) > Number($this.val())) {
                $this.val(0);
                var $reqMsg = $('.udu__min-req-message');
                if ($reqMsg.length > 0) {
                	if (!$this.attr('id')) {
                        $this.attr('id', 'inp-' + app.guid())
					}
                    setTimeout(
                    	function() {
                            app.balloon.showInfo('#' + $this.attr('id'), '<div style="max-width: 290px">' + $reqMsg.html() + '</div>', {side: ['top', 'bottom', 'right']});
						},
						100
					);
				}
                app.doFlicker($this);
			}
            var maxVal = $this.data('udu-max-qty');
            if ((maxVal || maxVal === 0) && (Number($this.val()) > Number(maxVal))) {
                $this.val(maxVal);
                app.doFlicker($this);
                var msg = $this.data('udu-max-qty-msg');
                if (msg) {
                    if (msg.substr(0, 9) === '__uduMsg:') {
                        app.alertMsg(
                            msg.substr(9),
                            null,
                            {
                                replace: {
                                    '%MAX%': maxVal
                                }
                            }
                        );
                	} else {
                    app.alert(msg.replace('%MAX%', maxVal));
                }
            	}
                app.events.trigger('udu_MAX-QTY-EXCEED', {$item: $this, maxVal: maxVal});
            }
        }
	},
	browser: {
		isIOS: function() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		}
	},
	file: {
		ext: function(fileName) {
            if (!fileName) {
                return '';
            }
            return fileName.split('.').pop().toLowerCase();
		},
		baseName: function(fileName) {
			if (!fileName) {
				return '';
			}
			return String(fileName).replace(/\\/g, '/').split('/').pop();
		},
		getPageUrlByName: function(fileName) {
            return app.ajaxProcessURLs.process.replace('/ajax-process.html', '/' + fileName);
		}
	},
	json: {
		map: function (obj, callBack) {
			if (typeof obj !== 'object') {
				return true;
			}
			if (Array.isArray(obj)) {
				for (var i = 0; i < obj.length; i++){
					if (typeof obj[i] === 'object') {
						app.utils.json.map(obj[i], callBack)
					} else {
						obj[i] = callBack(i, obj[i]);
					}
				}
			} else {
				for (var a in obj) {
					if (typeof obj[a] === 'object') {
						app.utils.json.map(obj[a], callBack)
					} else {
						obj[a] = callBack(a, obj[a]);
					}
				}
			}
		}
	}
};

app.utils.css = {
	cssVar: function (cssName, cssVal) {
		var r = document.querySelector(':root');
		if (arguments.length === 1) {
			return r.style.getPropertyValue(cssName);
		} else if (arguments.length === 2) {
			r.style.setProperty(cssName, cssVal);
			return app;
		}
	}
};

/**
 * Deletes product from Cart by id and ask confirmation
 *
 * @param {string} id
 * @returns {void}
 */
app.deleteCartProduct = function(id) {
	app.confirmMsg(
		'_order_product_list_Really_remove',
		function() {
			app.blockWindow(
				true,
				true,
				function() {
					app.events.trigger(
						'udu-CART-ITEM-REMOVED',
						{
							id: id
						}
					);
					$.post(
						$('form.del-form').attr('action'),
						{
                            cartItemKey: id
						},
						function() {
							app.url.goCart();
						}
					)
				},
				'P_W'
			)
		}
	)
};

/**
 * Deletes product from Popup Cart by id and ask confirmation - html param
 *
 * @param {string} id
 * @returns {void}
 */
app.deletePopupCartProduct = function(id) {
	var that = this;
	app.confirmMsg(
		'_order_product_list_Really_remove',
		function() {
			var $cartDiv = $('.div-cart-table');

			$cartDiv.fadeTo(300, 0);

			$('.tr-popup-' + id)
				.fadeOut(
				function() {
					$(this).remove();
					var $myCart = $('.my-cart, a.udu__my-cart');
					if ($('.cart-popup tr').length === 0) {
						if (!$myCart.hasClass('permanent')) {
							$('.my-cart, .my-cart-popup, .checkout-link').fadeOut(
								function() {
									$(this).remove();
								}
							);
						} else {
							$myCart.closest('li')
								.addClass('empty-cart');
							$('.udu__my-cart-popup').addClass('empty-cart');
                            $('.checkout-link').fadeOut(
                                function() {
                                    $(this).remove();
                                }
                            );
						}
					}
					app.balloon.saveContent('.udu__cart-popup-place', $('.udu__my-cart-popup').parent('div').html());
					app.events.trigger('udu-ITEM-DELETED-FROM-CART', {id: id});
				}
			);

			that.getURL(
				'cart/remove-product',
				function(url) {
					$.post(
						url,
						{
							cartItemKey: id
						},
						function() {
							if ($cartDiv.length > 0) {
								$.get(
									app.ajaxProcessURLs.process,
									{
										op: 'cart-table'
									},
									function(data) {
										$cartDiv.html(data).fadeTo(1, 200);
										app.events.trigger(
											'udu-AFTER-ITEM-DELETED-FROM-CART',
											{
												id: id,
												html: data
											}
										);
									}
								)
							}

							app.getScript(
                                'https://cdn.uducat.com/static/esb/js/main.prod.order.js',
								function() {
                                	app.updateHeaderCartInfo();
								}
							);

						}
					)
				}
			);
		}
	)
};

app.splash = {
	enable: function() {
        app.cookies.createCookie('splashPageUnblocked', 'N');
	}
};

app.jSettings = {
	getByKey: function(key) {
        var savedData = app.getLData('get-all-json', true);
        if (savedData[key]) {
        	return savedData[key];
		}
		return '';
	}
};

/**
 * Calls confirm box by Msg Box
 *
 * @param {string} html
 * @param {function} onYes
 * @param {function}onNo
 * @returns {void}
 */
app.confirmMsg = function(html, onYes, onNo, params) {
    params = params || {};
    app.onOk  = null;
    app.onYes = onYes;
    app.onNo  = onNo;
    app.blockWindow(true);
    app.initializeMessages(
        html,
        function() {
            var msg = app.getMsg(html);
            if (window.admin) {
                if (!params.rightButtonHTML) {
                    params.rightButtonHTML = '';
                }
                params.rightButtonHTML += '<a class="wnd-edit-link" href="#">Edit</a>';
            }
            if (params.doBeforeShow) {
            	msg = params.doBeforeShow(msg);
			}
            if (params && params.replace) {
                for (var i in params.replace) {
                    msg = msg.replace(i, params.replace[i]);
                }
            }
            app.showWindow(msg, 'CONFIRM', params);
            if (window.admin) {
                $('.wnd-edit-link').click(
                    function(e) {
                        e.preventDefault();
                        admin.editMsg(html);
                    }
                )
            }
        }
    );
};


/**
 * Makes multiple product ordering - will be overriten
 *
 * @param {Object} inForm
 * @returns {void}
 */
app.makeMultipleOrder = function(inForm) {
    this.getScript(
        'https://cdn.uducat.com/static/esb/js/main.prod.mult.order.js',
        function () {
            app._initializeMultipleOrder(app._vars.msg_initializeMultipleOrder);
            $(inForm).submit();
        }
    );
};

/**
 * Prepare Multiple ordering form
 *
 * @param {string} msg
 * @returns void
 */
app.initializeMultipleOrder = function(msg) {
	app._vars.msg_initializeMultipleOrder = msg;
	this.getScript(
		'https://cdn.uducat.com/static/esb/js/main.prod.mult.order.js',
		function () {
            app._initializeMultipleOrder(msg);
        }
	);
};

/**
 * Flick Item - red / white for 1 second
 * @param {Object} $item
 */
app.doFlicker = function($item, dur) {
	if (!dur) {
		dur = 610;
	}
	$item.addClass('bg-red-white-flicker');
    setTimeout(
        function() {
            $item.removeClass('bg-red-white-flicker');
        },
		dur
    )
};

/**
 * Clear Cart - with confirmation
 */
app.clearCart = function() {
	app.url.getUrls();
	app.confirmMsg(
		'_Clear_Cart_Confirm',
		function () {
			app.getScript(
				'https://cdn.uducat.com/static/esb/js/main.clear.cart.js',
				function () {
					app.removeFromCartV2.run();
                }
			)
        }
	)
};

/**
 * Recalculates grouped items
 * @param item
 */
app.recalcGroupedItems = function(item) {
	app.blockWindow(
		true,
		true
	);
    app.getScript(
        'https://cdn.uducat.com/static/esb/js/main.clear.cart.js',
        function () {
            app.doRecalcGroupedItems(item);
        }
    )
};

/**
 * Returns Message for Multiple Product ordering process
 * @returns {string}
 * @private
 */
app._getMultMessageText = function() {
	if (app.minMultQuantity === 1) {
		return '';
	}
	var cnt = 0;
	$('.mult-list input.number').each(
		function() {
			if (Number($(this).val())) {
				cnt += Number($(this).val());
			}
		}
	);
	var inCartCount = 0;
	if (app.prodInfo && app.prodInfo.inCartCount) {
		inCartCount = app.prodInfo.inCartCount;
	}
	if (cnt === 0 || cnt >= app.minMultQuantity - inCartCount) {
		return '';
	} else {
		var needCnt = String(app.minMultQuantity - cnt - inCartCount);
		if (inCartCount) {
			needCnt += '<span class="top-asterisk">*</span>';
		}
		var msg = app.minMultMessage
			.replace(/{minQnt}/g, app.minMultQuantity)
			.replace(/{selQnt}/g, String(cnt))
			.replace(/{addQnt}/g, needCnt);

		if (inCartCount > 0) {
			if (this.getLang() === 'en') {
				var wrd = 'Already in Cart';
			} else {
				wrd = 'Déjà dans le panier';
			}
			msg += ' <span class="in-cart-count"><span class="top-asterisk">*</span>' + wrd + ': ' + inCartCount;
		}

		return msg;
	}
};

/**
 * Will be called after preview item is loaded to make some corrections for it
 *
 * @param {Object} itm
 * @returns {void}
 */
app.previewLoaded = function(itm) {
	$(itm).css('visibility', 'visible')
		.closest('li')
		.removeClass('loading');

	$(itm).closest('.att-link')
		.click(
			function() {
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
				} else {
					$(this).addClass('active');
				}
			}
		)
		.find('a')
		.css('bottom', ($(itm).height() - 44) / 2)
};

/**
 * Prints Product List
 *
 * @returns {void}
 */
app.doPrintProductList = function() {
	$('div.printable-area').remove();
	$('body').append('<div class="printable-area"></div>');

	var $printArea = $('div.printable-area');

	var $bi = $('.billing-info');
	var $si = $('.shipping-info');
	var territory = $bi.find('.territory .value').html();

	if (territory) {
		territory = ', ' + territory;
	} else {
		territory = '';
	}

	var html = '<h1>' + $('.d1 h1').html() + '</h1>';
		html += '<table class="shipping-info">';
		html += '<tr>';
		html += '<td class="td-tbl">';
		html += '<table class="top-info">';
		html += '<tr><th>' + $('#legend-billing-info').html() + '</th></tr>';
		html += '<tr><td>' + $bi.find('.company-info .value').html() + '</td></tr>';
		html += '<tr><td>' + $bi.find('.first-name .value').html() + ' ' + $bi.find('.last-name .value').html() + '</td></tr>';
		html += '<tr><td>' + $bi.find('.address-line-1 .value').html() + '</td></tr>';
		html += '<tr><td>' + $bi.find('.city .value').html() + territory + ', ' + $bi.find('.postal-code .value').html() + '</td></tr>';
		html += '<tr><td>' + $bi.find('.country .value').html() + '</td></tr>';
		html += '<tr><td>' + $bi.find('.phone .label').html() + ' ' + $bi.find('.phone .value').html() + '</td></tr>';
		html += '</table>';
		html += '</td>';
		html += '<td></td>';
		html += '<td class="td-tbl">';
		html += '<table class="top-info">';
		html += '<tr><th>' + $('#legend-shipping-info').html() + '</th></tr>';
		html += '<tr><td>' + $si.find('.company-info .value').html() + '</td></tr>';
		html += '<tr><td>' + $si.find('.first-name .value').html() + ' ' + $si.find('.last-name .value').html() + '</td></tr>';
		html += '<tr><td>' + $si.find('.address-line-1 .value').html() + '</td></tr>';
		html += '<tr><td>' + $si.find('.city .value').html() + territory + ', ' + $si.find('.postal-code .value').html() + '</td></tr>';
		html += '<tr><td>' + $si.find('.country .value').html() + '</td></tr>';
		html += '<tr><td>' + $si.find('.phone .label').html() + ' ' + $si.find('.phone .value').html() + '</td></tr>';
		html += '</table>';
		html += '</td>';
		html += '</tr>';
		html += '</table>';

	$('table.list tr').each(
		function() {
			html += '<table class="line"><tr>' + $(this).html() + '</tr></table>';
		}
	);

	var $totals = $('._ .tax-table tr.level-1 td.value span, ._ .tax-table tr.level-0 td.value span');
	if ($totals.length > 0) {
		$totals.each(
			function() {
				var $tr = $(this).closest('tr');
				html += '<table class="line summary"><tr>' +
					'<td colspan="4" class="sum-title"><span class="label">' + $tr.find('td.label').html() + '</span>' +
					'<td class="subtotal ' + $tr[0].className + '"><span class="money">' + $(this).html() + '</span></td>' +
					'</td></tr></table>';
			}
		)
	}

	$printArea.html(html);
	if ($totals.length > 0) {
		$printArea.find('td[colspan="5"]').closest('table').remove();
	}

	window.print();
};

/**
 * Prints Product List
 *
 * @returns {void}
 */
app.initializeCartLinks = function() {
	$("li.att-link a.cart-zoom, .options a, tr.products td a").fancybox({
		'transitionIn': 'elastic',
		'transitionOut': 'elastic',
		onStart: function() {
			setTimeout(
				function() {
					$('table li.active').removeClass('active');
				},
				100
			);
		}
	});
};

/**
 * Clears Payment Methods Settings with Confirmation
 *
 * @param {string} inText
 * @returns {void}
 */
app.clearPaymentMethodSetting = function(inText) {
	app.confirm(
		inText,
		function() {
			$('button[name="clearData"]')
				.attr('onclick','')
				.unbind('click')
				.click();
		}
	);
};

/**
 * Sets local data in Local Storage
 *
 * @param {string} key
 * @param {string} data
 * @param {bool} onSessionLevel
 * @returns {*}
 */
app.setLData = function (key, data, onSessionLevel) {
	try {
		if (!window.localStorage) {
			return data;
		}
	} catch (e) {
		return data;
	}

    key = app._getSK(key);
	if (typeof onSessionLevel === 'boolean') {
		window.sessionStorage[key] = JSON.stringify(data);
	} else if (typeof onSessionLevel === 'number'){
		window.localStorage[key] = JSON.stringify({data: data, __date: app.utils.date.curDate()});
	} else {
		window.localStorage[key] = JSON.stringify(data);
	}
	return data;
};

/**
 * Returns Storage Key based on Store Id
 * @param key
 * @returns {*}
 * @private
 */
app._getSK = function(key) {
	if (key.indexOf('_uduG__') >= 0) {
		return key;
	}
    var regExp = new RegExp(':' + app.storeId + '$');
    if (!regExp.test(key)) {
    	if(app.storeId) {
            key += ':' + app.storeId;
		} else (
			key += ':' + app.getLData('_uduG__SID')
		)
	}
	return key;
};

/**
 * Gets local data from Local Storage
 *
 * @param {string} key
 * @param {bool} onSessionLevel
 * @returns {*}
 */
app.getLData = function(key, onSessionLevel) {
	try {
		if (!window.localStorage) {
			return '';
		}
	} catch(e) {
		return '';
	}
	key = app._getSK(key);
	if (typeof onSessionLevel === 'boolean') {
		if (!window.sessionStorage[key]) {
			return '';
		}
		return JSON.parse(window.sessionStorage[key]);
	} else {
		if (!window.localStorage[key]) {
			return '';
		}
		var res = {};
		try {
			res = JSON.parse(window.localStorage[key]);
		} catch(e) {
			res = window.localStorage[key];
		}

		if (typeof onSessionLevel === 'number') {
			if (typeof res !== 'object') {
				return '';
			}
			if (!res['__date']) {
				return '';
			}
			if (app.utils.date.datesDiffInDays(res['__date']) > onSessionLevel) {
				return '';
			}
			if (res['data']) {
				return res['data'];
			}
			return '';
		}

		return res;
	}
};

/**
 * Saves some information about Product List - to be used to navigation to the latest
 * visited product list and create prev/next buttons
 *
 * @returns {void}
 */
app.saveProductListInfo = function() {
	var $h1 = $('h1');
	var title = '***';
	if ($h1.length > 0) {
		title = $h1.html().replace(/(<([^>]+)>)/ig,"");
	} else if (app._vars.pageTitle) {
		title = app._vars.pageTitle;
	}

	title = app.utils.str.unescape(title);

	var pages = app.getLData('uduUrl2Title');
	if (!pages) {
		pages = {};
	}

	pages[document.location.href] = title;

	app.setLData('uduUrl2Title', pages);

    if (app.prodInfo || app._vars._saveProductListInfoSaved) {
        return false;
    }
    app._vars._saveProductListInfoSaved = true;
	var lName = 'udu-product-list-info';
	var listData = this.getLData(lName);
	if (listData) {
        var $a = $('a.continue');
        var url = listData.url;
        if ($a.length > 0 && url) {
        	if (app.getLang() === 'en') {
        		url = url.replace('/fr/', '/en/');
			} else {
				url = url.replace('/en/', '/fr/');
			}
            $a.attr('href', url);
		}
	}
    var $items = $('.product-list2 .name a');
	if ($items.length > 0) {
        var data = {};
        $items.each(
            function() {
                var url = $(this).attr('href');
                var $item = $(this).closest('.item');
                data[url.split('/').pop()] = {
                    url: url,
                    name: $(this).html(),
					img: $item.find('.image img').attr('src')
                };
            }
        );

        this.setLData(
        	lName,
			{
				data: data,
				url: String(document.location),
				title: title
			}
		)
	}
};

/**
 * Creates Prev/Next buttons
 *
 * @param {string=} createNextButton
 * @returns {void}
 */
app.createPrevNextButtons = function(createNextButton) {
	if (!createNextButton) {
        createNextButton = 'Y';
	}
	var $prodDiv = $('div.product-view');
	if ($prodDiv.length === 0) {
		return;
	}
	app.__createNextButton = createNextButton;
	app.getScript(
		[
			'https://cdn.uducat.com/static/esb/udu-css/css/style-prod-detail.css',
			'https://cdn.uducat.com/static/esb/js/main.prod.detail.js'
		],
		null
	);
};

/**
 * Gets URL by action
 *
 * @param {string} act
 * @param {function} callBack
 * @returns {string}
 */
app.getURL = function (act, callBack) {
    var key = 'udu__l-urls:' + app.storeId;
    var data = this.getLData(key, true) || {};
	var url = data[act];
	if (url) {
		if (callBack) {
			callBack (url);
		}
		return url;
	} else {
		app.getInfo(
            'get-url',
			{
                act: act
			},
			function(url) {
            	data[act] = url;
                app.setLData(key, data, true);
                if (callBack) {
                    callBack (url);
                }
			}
		);
	}
	return '';
};

/**
 * Initializes index Slider
 *
 * @param {{}} options
 * @returns {void}
 */
app.makeIndexSlider = function(options) {
	var that = this;
	if (typeof options === 'object') {
		var duration = options.duration;
		var slType = options.slType;
	} else {
		duration = options;
		options = {};
	}
	if (!Number(duration)) {
		duration = 5000;
	}
	if (!options.selector) {
        var $nivoSlider = $('.nivoSlider');
        this.iniIndexSliderHTML = $nivoSlider.html();
	} else {
        $nivoSlider = $(options.selector);
	}

	var linkCount = $nivoSlider.find('a').length;

	if (linkCount <= 1) {
		duration = 1000000000;
	}

	if ($.fn.nivoSlider) {
		$nivoSlider
			.nivoSlider({
				//effect: 'boxRandom,boxRain,boxRainReverse,slideInLeft,boxRainGrow,slideInRight',
				effect: 'sliceUpLeft,sliceUp,sliceDownLeft,sliceDown,boxRandom,boxRain,boxRainReverse,boxRainGrow,boxRainGrowReverse',
				prevText: '',
				nextText: '',
				pauseTime: duration
			});

		if ($nivoSlider.hasClass('udu__indexSliderControl')) {
			app.getScript(
				['https://cdn.uducat.com/static/esb/udu-css/css/slider-control-buttons.css'],
				function() {
					$nivoSlider.append('<div class="nivo-controls-auto"><a class="btn play active">' + app.lang.selectWord('play', 'jouer') + '</a><a class="btn stop">' + app.lang.selectWord('stop', 'arrêter') + '</a></div>');
					var $btnStop = $nivoSlider.find('.stop');
					var $btnPlay = $nivoSlider.find('.play');
					$btnPlay.on('click',function(){
						$(this).addClass('active');
						$btnStop.removeClass('active');
						$nivoSlider.data('nivo:vars').stop = false;
					});
					$btnStop.on('click',function(){
						$(this).addClass('active');
						$btnPlay.removeClass('active');
						$nivoSlider.data('nivo:vars').stop = true;
					})
				}
			)
		}

	} else if ($.fn.bxSlider) {
		$nivoSlider.each(
			function() {
				var html = '<ul class="bxSlider">';
				$(this).find('> a').each(
					function() {
						var $this = $(this);
						var trg = $this.attr('target');
						if (trg) {
							trg = 'target="' + trg + '"'
						} else {
							trg = '';
						}

						var evtData = {$item: $this, html: $this.html()};
						app.events.trigger('udu_BEFORE-BX-SLIDER-ITEM-REBUILT', evtData);
						html += '<li><a class="nivo-imageLink" ' + trg + ' href="' + $this.attr('href') + '">' + evtData.html + '</a></li>';
					}
				);
				html += '</ul>';
				$(this)
					.addClass('bx-wrapper')
					.html(html);

				$('.li-nivo-wrapper')
					.addClass('li-bx-wrapper');
			}
		);

		var resp = true;
		if (slType === 'bx2') {
			resp = false;
		}

		var bxOptions = {
			pause: duration,
			auto: true,
			responsive: resp,
            touchEnabled: false
		};

		if ($nivoSlider.hasClass('udu__indexSliderControl')) {
			bxOptions.autoControls = true;
			bxOptions.stopAutoOnClick = true;
			app.getScript(['https://cdn.uducat.com/static/esb/udu-css/css/slider-control-buttons.css']);
		}

		if (options.bxOptions) {
			for (var i in options.bxOptions) {
				bxOptions[i] = options.bxOptions[i];
			}
		}

		$('.bxSlider')
			.bxSlider(bxOptions);
		$('.bx-pager')
			.addClass('nivo-controlNav');
		$('._ .bx-prev')
			.addClass('nivo-prevNav');
		$('._ .bx-next')
			.addClass('nivo-nextNav');
		$('._ .bx-controls-direction')
			.addClass('nivo-directionNav');
	}

	if (linkCount <= 1 && !options.selector) {
		$('.nivo-controlNav, .nivo-directionNav').remove();
	}

	$('._ .li-nivo-wrapper, .nivoSlider')
		.fadeTo(0, 0)
		.css('visibility', 'visible')
		.fadeTo(1000, 1);

	if (slType !== 'bx' && $('body').hasClass('center-slider')) {
		$(window).resize(
			function() {
				that.setSliderCenter();
			}
		);
		this.setSliderCenter();
	}

	if (slType === 'bx') {
		$("<style type='text/css'>body ._ .nivoSlider, body ._ .slider-wrapper, " +
			"body ._ .bx-viewport, body .theme-default #slider, body ul.bxSlider > li > a {height: auto !important}</style>").appendTo("head");
	} else if (slType === 'bx2') {
		$("<style type='text/css'>.bx-wrapper img {max-width: 4000px !important}</style>").appendTo("head");
	}

	if (options._VERSION > 1) {
		return false;
	}

	this.createMobileSlider();
    if (app.responsiveMode) {
        $('.nivoSlider, .slider-wrapper, ul.li-nivo-wrapper').addClass('responsiveMode');
    }

    app.events.trigger(
    	'udu-INDEX-BANNER-DONE',
		{
			slider: $nivoSlider
		}
	)
};

/**
 * Removes discount codes from form
 *
 * @param {string} inCode
 * @returns {void}
 */
app.removeDiscountCode = function(inCode) {
	app.blockWindow(true, true);
	$.ajax({
		type: "GET",
		url: $('table.tax-table').data('udu-remove-discount-code-url'),
		data: {
			code: inCode
		},
		success: function() {
			app.refreshDiscountBlock();
		}
	});
};

/**
 * Refreshes Discount Codes
 *
 * @returns {void}
 */
app.refreshDiscountBlock = function() {
	app.blockWindow(false);
	$('.price-summary-table')
		.addClass('loading')
		.find('.tax-table')
		.fadeTo(
		300,
		0.2,
		function() {
			$.ajax({
				type: "GET",
				url: app.ajaxProcessURLs.process,
				data: {
					op: 'price-summary'
				},
				success: function(data) {
					$('#taxOrderNote').remove();
					$('.price-summary-table')
						.fadeTo(0.2)
						.removeClass('loading')
						.html(data)
						.fadeTo(400, 1);
					$('._ .tax-table button.remove')
						.prop("disabled", false)
						.click(
						function() {
							app.removeDiscountCode($(this).data('udu-component-title'));
							app.events.trigger(
								'udu_DISCOUNT-CODE-REMOVE',
								{

								}
							)
						}
					)
				}
			})
		});
};

/**
 * Adds discount codes to form
 *
 * @param {Object} inForm
 * @returns {void}
 */
app.addDiscountCode = function(inForm) {
	var url = $(inForm).attr('action');
	app.blockWindow(true, true);
	$.ajax({
		type: "POST",
		url: url,
		data: $(inForm).serialize(),
		success: function(data) {
			if (data.indexOf('popup-error') > 0) {
				app.alert(data);
			} else {
				var $input = $('.code-submit input');
				var code = $input.val();
				$input.val('');
				app.refreshDiscountBlock();
				if (app.doAfterDiscountCodeAdded) {
					app.doAfterDiscountCodeAdded(code);
				}
			}
		}
	});
};

/**
 * Show/Hides menu items popups in the header menu by clicking
 *
 * @param {Object} btn
 * @param {boolean=} forceHide
 * @returns {void}
 */
app.showMenuPopup = function (btn, forceHide) {
	var $body = $('body');
	if (!forceHide) {
		$('.header .clicked, .my-cart-line .clicked, .ul-my-cart .clicked').each(
			function() {
				if (this !== btn) {
					app.showMenuPopup (this, true);
				}
			}
		);
	}
	if (!btn) {
		return;
	}
	var $btn = $(btn);
	var $ul = $btn.closest('li').find('.ul-mega-1');
	if ($ul.length === 0) {
		$ul = $btn.closest('.ul-mega-1');
	}
	if ($ul.hasClass('hidden') && !forceHide) {
		$ul.show()
			.removeClass('hidden')
			.stop()
			.fadeTo(300, 1)
			.closest('li')
			.addClass('hover with-children')
			.find('.delimiter')
			.removeClass('hidden');
		$btn.addClass('clicked');
        $body.addClass('udu__cart-popup-open');
	} else {
		$ul.hide()
			.stop()
			.addClass('hidden')
			.fadeTo(200, 0.01)
			.closest('li')
			.removeClass('hover')
			.find('.delimiter')
			.addClass('hidden');
		$btn.removeClass('clicked');
        $body.removeClass('udu__cart-popup-open');
	}
};

/**
 * Gets Cart Popup
 * @param {{}} params
 * @param {function} callBack
 * @returns {void}
 */
app.getCartHtml = function(params, callBack) {
	if (!params) {
		params = {};
	}
    params.method = 'GET';
	var $ul = $('.my-cart-popup');
	if ($ul.hasClass('loading') && !$ul.hasClass('busy')) {
		$ul.addClass('busy');
		app.getInfo(
            'get-cart',
			params,
            function(data) {
            	var $place = $ul.find('>li');
            	if ($place.length === 0) {
					$place = $ul;
				}
                $ul.removeClass('loading');
            	data += '<div class="udu__close hidden" onclick="app.showMenuPopup(this, true)"></div>';
                $place.hide()
                    .html(data)
                    .fadeIn();

                var prodCnt = $place.find('table.cart-popup tr td.subtotal').length;
                if (prodCnt > 0) {
                    $('.empty-cart').removeClass('empty-cart');
				}

				$('.my-cart-count').html(prodCnt);

                if (callBack) {
                    callBack($place);
				}

                $ul.find('tr').mouseover(
                    function() {
                        $(this).addClass('over');
                    }
                )
            }
		);
	}
};

/**
 * Sets mask for Phone/Fax input items
 *
 * @param {string} inMask
 * @returns {void}
 */
app.setPhoneMask = function(inMask) {
	$('input.phone, input.fax, tr.phone td.control input, tr.fax td.control input').each(
		function() {
			$(this).mask(inMask, {placeholder: "X"});
			$(this).attr('placeholder', inMask.replace(/n/g, 'X').replace('?', '').replace(/a/g, 'A'));
		}
	);
};

/**
 * Corrects Top Menu Popups - in case they are to long, so system will
 * divide them to separate lines
 *
 * @deprecated
 * @param {{}} params
 * @returns {void}
 */
app.correctTopMenuPopups = function(params) {
	var hideTime = 500;
	var maxPopupItemLength = 16;
	var ieVer = app.getInternetExplorerVersion();
	if (ieVer > 0 && ieVer < 9) {
		hideTime = 1;
	}
	$(document).click (
		function() {
			app.showMenuPopup(null);
		}
	);
	$('.menu, .my-cart-line').click(
		function(e) {
			e.stopPropagation();
		}
	);
	if (!params) {
		params = {};
	}
	if (params.maxPopupItemLength) {
		maxPopupItemLength = params.maxPopupItemLength;
	}
	if (!app.menuPopupWidth) {
		app.menuPopupWidth = 200;
	}
	if (params.mode === 'advanced') {
        $('._ .menu .delimiter').html('<div class="inner schema-brd-color schema-bg-color"></div>');
		$('._ .menu > ul > li.with-children > div.top-title').mouseenter(
			function() {
				var $li = $(this).closest('li');
				clearTimeout($li.data('udu-timer-id'));
				$li.find('> ul.ul-mega-1, > .top-title > .delimiter').show();
				$li.data(
					'udu-timer-id2',
					setTimeout(
						function() {
							$li.addClass('mouse-over closing-popup');
						},
						1
					)
				);
			}
		);
		$('._ .menu > ul > li').mouseleave(
			function() {
				var that = this;
				clearTimeout($(this).data('udu-timer-id2'));
				$(this).removeClass('mouse-over')
					.addClass('closing-popup')
					.data(
						'udu-timer-id',
						setTimeout(
							function() {
								$(that)
									.removeClass('closing-popup')
									.find('> ul.ul-mega-1,> .top-title > .delimiter')
									.hide();
							},
							hideTime
						)
					);
			}
		)
	}
	$('.menu li.with-children').mouseover (
		function() {
			var $ul = $(this).find('> ul');
			if ($ul.length === 1) {
				var dif = $ul.width() + $ul.offset().left - $(window).width();
				if (dif > 0) {
					var lt = parseInt($ul.css('left'));
					$ul.css('left', lt - 20 - dif);
				}
				var off = $ul.offset();
				if (off.left < 0) {
					var l = parseInt($ul.css('left')) - off.left;
					$ul.css('left', l);
				}
			}
		}
	);
	$('.menu li.with-children ul').each(
		function() {
			var $parent = $(this)
				.closest('li.with-children')
				.mouseenter (
					function() {
						if ($(this).hasClass('hover')) {
							return;
						}
						$(this).find('ul.ul-mega-2')
							.each(
							function() {
								$(this).css('left', $(this).closest('li').outerWidth() - 1);
							}
						);
					}
				);
			if ($(this).hasClass('cat-column')) {
				return;
			}
			var $li = $(this).find('> li');
			if($li.length > maxPopupItemLength) {
				var pos = 0;
				var itemPerColumn = maxPopupItemLength;
				if ($li.length < 45) {
					itemPerColumn = Math.ceil($li.length / 3);
				}
				var columnCount = Math.ceil($li.length / itemPerColumn);
				var html = '<ul class="multi-columns" style="width: ' + ((app.menuPopupWidth + 5) * columnCount) + 'px;">';
				$li.each(
					function() {
						if (pos === 0) {
							html += '<li style="padding:0 5px !important; border: none !important;background: transparent !important;width: ' + app.menuPopupWidth + 'px"><ul>'
						}
						if ($(this).hasClass('with-children')) {
							html += '<li class="with-children">' + $(this).html() + '</li>';
						} else {
							html += '<li>' + $(this).html() + '</li>';
						}
						pos++;
						if (pos >= itemPerColumn) {
							html += '</ul></li>';
							pos  =  0;
						}
					}
				);

				if ($li.length % itemPerColumn !== 0) {
					html += '</ul></li>';
				}

				html += "</ul>";

				var ulClass = '';
				var elUl = $parent.find('> ul')[0];
				if (elUl) {
					ulClass = elUl.className;
				}

				$parent.find('ul').remove();
				var parWidth = $parent.outerWidth();
				$parent.append(html);
				$parent.find('> ul')
					.css('left', (parWidth - (app.menuPopupWidth + 5) * columnCount) / 2)
					.addClass(ulClass);
			}
		}
	);
	$('.menu ul').mouseover(
		function() {
			if (!$(this).hasClass('viewed')) {
				$(this).addClass('viewed');
			}
		}
	)
};

/**
 * Runs jCarousel for Index Page
 *
 * @param {{}} params
 * @returns {void}
 */
app.runCarusel = function(params) {
	var html = '';
	if (!params){
		params = {};
	}
	if (!params.selector) {
		params.selector = 'ul.jcarousel-skin-index';
	}
	var $carousel = $(params.selector);
	if ($carousel.length === 0) {
		return false;
	}
	if (!params.auto) {
		params.auto = 3;
	}
	if (!params.scroll) {
		params.scroll = 3;
	}
	if (!params.iniSelector) {
        params.iniSelector = '#index-slider-ini';
	}
	var $sliderIni = $(params.iniSelector);

	if ($sliderIni.length > 0) {
		$sliderIni.find('a').each(
			function() {
				var addCssClass = "";
				if ($(this).find('.nivo-add-html').length === 0) {
					addCssClass += 'empty-text';
				}
				if (!$(this).attr('href')) {
					addCssClass += ' no-link';
				}
				var target = $(this).attr('target');
				if (target && target != 'undefined') {
					target = 'target="' + target + '"';
				} else {
					target = '';
				}
				html += '<li class="item ' + addCssClass + '"><div class="image">' +
					'<a ' + target + ' href="' + $(this).attr('href') + '">' +
					$(this).html() +
					'</a>' +
					'</div>';
				if (params.innerHTML) {
					html += params.innerHTML;
				}
				html +=	'</li>';
			}
		);
		$carousel.html(html);
	}

	$carousel.find('.image a').each(
		function() {
			var attr = String('target,href').split(',');
			var $link = $(this).closest('li.item')
				.find('a.linker');
			if ($link.length > 0) {
				for (var i = 0; i < attr.length; i++) {
					if ($(this).attr(attr[i]) && $(this).attr(attr[i]) != "undefined") {
						$link.attr(attr[i], $(this).attr(attr[i]));
					}
				}
			}
		}
	);
    this.iniCaruselHTML = $carousel.html();
	if (!params.minQty) {
        var tmpHtml = $carousel.html().repeat(6);
	} else {
		var itemCnt = $carousel.find('li.item').length;
		if (itemCnt >= params.minQty) {
            tmpHtml = $carousel.html().repeat(6);
		} else {
            params.auto = 99999;
            params.scroll = 99999;
            $('body').addClass('_carousel-inactive-mode _carouse-cnt-' + itemCnt);
		}
	}
    $carousel.html(tmpHtml);
	$carousel.jcarousel({
		wrap: 'circular',
		scroll: params.scroll,
		auto: params.auto,
		create: $carousel.hover(function () {
				$(this).jcarousel({
					auto: 99999
				});
			},
			function () {
				$(this).jcarousel({
					auto: params.auto
				});
			})
	});
	this.createMobileSlider();
};

/**
 * Creates necessary HTML for Mobile Sliders
 *
 * @returns {void}
 */
app.createMobileSlider = function() {
	if (window.appMobile && appMobile.isMobileMode && appMobile.isMobileMode() && appMobile.createMobileSlider) {
		appMobile.createMobileSlider();
	}
};

/**
 * Corrects Main Slider position based on Screen Width and set necessary position for Pagination Links
 *
 * @returns {void}
 */
app.setSliderCenter = function() {

	if (!app.$leftSliderNav || app.$leftSliderNav.length === 0) {
		app.$leftSliderNav = $(".nivo-directionNav a.nivo-prevNav");
		app.$rightSliderNav = $(".nivo-directionNav a.nivo-nextNav");
		app.$leftSliderNav.data('udu-ini-left', parseInt(app.$leftSliderNav.css('left')));
		app.$rightSliderNav.data('udu-ini-right', parseInt(app.$rightSliderNav.css('right')));
		app.$nivoSlider = $('.nivoSlider');
		app.$nivoControlNav = $('.nivo-controlNav');
	}

	var wd = $('._').width();
	if (wd >= 1120 || app.responsiveMode) {
		var marg = (1920 - wd) / 2;
	} else {
		marg = 0;
	}

	app.$nivoSlider.css ({
		marginLeft : -marg
	});
	var topPos = app.$nivoSlider.height() / 2 - app.$leftSliderNav.height() / 2;
	app.$leftSliderNav.css({
		left: Number(app.$leftSliderNav.data('udu-ini-left')) + marg,
		top: topPos
	});
	app.$rightSliderNav.css({
		right: Number(app.$rightSliderNav.data('udu-ini-right')) + marg,
		top: topPos
	});
	app.$nivoControlNav.css({
		paddingLeft: marg,
		paddingRight: marg
	});
};

/**
 * Corrects column width for Pricing table to be the same
 *
 * @returns {void}
 */
app.correctPriceTables = function() {
	for(var x = 0; x < 100; x++) {
		var arr = $('table.price-table th.pos-' + x);
		if (arr.length === 0) {
			break;
		}
		arr.width(Math.max.apply(Math, arr.map(function(){ return $(this).width() + 4; }).get()));
	}
};

/**
 * Sets main image for Page Detail if option with such name exists
 *
 * @param {string} src
 * @returns {void}
 */
app.setMainImageByOption = function(src) {
	setTimeout(
		function() {
			$('._ .product-view div.image-inner.edit-mode').removeClass('edit-mode');
		},
		1000
	);
	if (!src) {
		return;
	}
	$('.product-images.options img[src$="' + src.split('/').pop() + '"]').closest('li').click();
};

/**
 * Tries to repair image
 *
 * @param {Object} img
 * @returns {void}
 */
app.onError = function(img) {
	var $i = $(img);
	if ($i.data('uduDone') === 'Y') {
		$i.attr('src', $i.data('error-src'));
		return ;
	}

	$i.data('uduDone', 'Y');
	var src = $i.attr('src') + '?v=' + Math.round(Math.random() * 10000);
	$i.attr('src', src);
};

/**
 * Corrects main part for site to fit full screen by height
 *
 * @returns {void}
 */
app.correctMainPartHeight = function() {
	if (!app._resizeForMainPartHeightWasAssigned) {
		$(window).resize(
			app.correctMainPartHeight
		);
		app._resizeForMainPartHeightWasAssigned = true;
	}
	var minusH = 0;
	$('.minus-from-main').each(
		function () {
			minusH += $(this).outerHeight();
        }
	);
	$('._ .main:not(.not-correctable)').css('min-height', $(window).height() - $('._ > .top').outerHeight() - $('._ .footer').outerHeight() - minusH);
};

/**
 * Runs immediately in header
 *
 * @returns {void}
 */
app.runImmediate = function() {
	var res = app.url.doAfterLoginRedirect();
	if (res) {
		return false;
	}
	if (app.docReferrer().indexOf('/user/log-in') >= 0 && this._usrLogged) {
		var href = this.getLData('redirectAfterLoginUrl');
		if (href) {
            $('html').css('display', 'none');
			this.setLData('redirectAfterLoginUrl', '');
			document.location = href;
		}
	}
	if (app.doAfterRunImmediate) {
		app.doAfterRunImmediate();
	}
};

/**
 * Returns color for selected class name
 *
 * @param {string} className
 * @returns {string}
 */
app.getClassColor = function(className) {
	return app.color.getCssClassColor(className);
};

/**
 * Additional correction to HTML to fit General CSS Style
 * @returns {void}
 */
app.fixCSS = function() {
	$('.payment-method-form-Budget table').addClass('list tbl-bordered small-font');
};

/**
 * Returns dir where JS files for template are allocated
 *
 * @returns {string}
 */
app.getJSDir = function () {
	var path = app.getLData('udu-sPath', true);
	if (path) {
		return path;
	}
	$('script[src*="all.js"], script[src*="main.core.js"], script[src*="main.js"]').each(
		function () {
            path = $(this).attr('src').split('/main.core.js')[0].split('/all.js')[0].split('/main.js')[0];
            return false;
		}
	);
	return app.setLData('udu-sPath', path, true);
};

/**
 * Returns dir where CSS files for template are allocated
 *
 * @returns {string}
 */
app.getCSSDir = function () {
    var path = '';
    $('link[href*="style.css"], link[href*="all.css"]').each(
        function () {
            path = $(this).attr('href').split('/style.css')[0].split('/all.css')[0];
            return false;
        }
    );
    return path;
};

app.scriptUtils = {
	reg: function(id, callBack) {
		if (!app._vars._scriptCallBacks) {
			app._vars._scriptCallBacks = {}
		}
		if (!app._vars._scriptCallBacks[id]) {
			app._vars._scriptCallBacks[id] = {
				loaded: false,
				calls: []
			};
		}
		if (callBack) {
			app._vars._scriptCallBacks[id].calls.push(callBack);
		}
	},
	run: function(id, data, force) {
		app.utils.setTimeout(
			function() {
				var info = app._vars._scriptCallBacks[id];
				if (force) {
					info.loaded = true;
				}
				if (info.loaded) {
					for (var i  = 0; i < info.calls.length; i++) {
						if (info.calls[i]) {
							info.calls[i](data);
							info.calls[i] = null;
						}
					}
				}
			},
			10,
			'tmr_' + id
		)
	}
};

/**
 * Add JS script from JS folder to the page
 * @param {string|object} name
 * @param {function} callBack
 * @param {{}} params
 * @returns {boolean}
 */
app.getScript = function(name, callBack, params) {
	var that = this;
	if (name === 'fancy') {
		name =  [
			'fancybox/fancybox.min.css',
			'fancybox/fancybox.min.js'
		];
	}

	params = params || {};

	if (!params.backMd5) {
		params.backMd5 = app.utils.str.md5(name);
		app.scriptUtils.reg(params.backMd5, callBack);
	}

	if (params.isLoaded) {
		app.scriptUtils.run(params.backMd5, name, true);
		return true;
	}
	if (!params.guid) {
		params.guid = app.guid();
	}
	if (!app._vars._getScriptCalls) {
		app._vars._getScriptCalls = {};
	}
	if (params.block) {
		app.blockWindow(true);
	} else if (params.wait) {
		app.blockWindow(true, true);
	}
	if (!this._scripts) {
		this._scripts = {};
	}
	if ($.fn.fancybox) {
		this._scripts['fancybox/fancybox.min.js'] = true;
		this._scripts['fancybox/fancybox.min.css'] = true;
	}

	if (typeof name === 'object') {
		for (var i in name) {
			if (typeof name[i] === 'string') {
				params.__requiredFiles = name;
				app.getScript(name[i], callBack, params);
			}
		}
		return false;
	}

	var isCSS = String(name).endsWith('.css');
	var path = name;
	if (String(name).indexOf('//') >=0) {
		// do nothing
	} else if (String(name).indexOf('/') >= 0) {
		path = String(app.getJSDir().slice(0,-2) + name).replace(/\/+/g, '/');
	} else if (isCSS) {
		path = this.getCSSDir() + '/' + name;
	} else {
		path = this.getJSDir() + '/' + name;
	}

	if (!isCSS) {
		var jsSel = 'script[src$="/' + name + '"]';
		if (name.indexOf('//') >= 0) {
			jsSel = 'script[src$="' + name + '"]';
		}
		if ($(jsSel).length > 0 && !this._scripts.hasOwnProperty(name)) {
			this._scripts[name] = true;
		}
	} else {
		var csSel = 'link[href$="/' + name + '"]';
		if (name.indexOf('//') >= 0) {
			csSel = 'link[href$="' + name + '"]';
		}
		if ($(csSel).length > 0) {
			this._scripts[name] = true;
		}
	}

	if (this._requiredScriptsLoaded(name, params)) {
		if (!app._vars._getScriptCalls[params.guid]) {
			app.scriptUtils.run(params.backMd5, path, true);
		}
		app._vars._getScriptCalls[params.guid] = true;
		return true;
	} else if (this._scripts[name]) {
		return false;
	}

	if (isCSS) {
		if (String(name).indexOf('/') >= 0) {
			$("<link/>", {
				rel: "stylesheet",
				type: "text/css",
				href: path
			}).appendTo("head");
			app._scripts[name] = true;
			if (that._requiredScriptsLoaded(name, params) ) {
				app._vars._getScriptCalls[params.guid] = true;
				app.scriptUtils.run(params.backMd5, path, true);
			}
		} else {
			$.ajax({
				type: "GET",
				url: path,
				success: function(data) {
					var imgDir = app.getCSSDir().slice(0, -3) + 'img';
					data = data.replace(/\[imgDir\]/g, imgDir);
					app._scripts[name] = true;
					$("head").append("<style>" + data + "</style>");
					app.events.trigger('udu_CSS-LOADED', {'scriptName': name});
					if (that._requiredScriptsLoaded(name, params)) {
						app._vars._getScriptCalls[params.guid] = true;
						app.scriptUtils.run(params.backMd5, path, true);
					}
				},
				cache: true
			});
		}
	} else {
		app._scripts[name] = false;
		$.ajax({
			type: "GET",
			url: path,
			success: function() {
				app._scripts[name] = true;
				app.events.trigger('udu_JS-LOADED', {'scriptName': name});
				if (that._requiredScriptsLoaded(name, params) && !app._vars._getScriptCalls[params.guid]) {
					app._vars._getScriptCalls[params.guid] = true;
					app.scriptUtils.run(params.backMd5, path, true);
				}
			},
			dataType: "script",
			cache: true
		});
	}
	return true;
};

/**
 * Defines if all necessary scripts were loaded
 * @param {string} name
 * @param {Object} params
 * @returns {boolean}
 * @private
 */
app._requiredScriptsLoaded = function(name, params) {
	if (!params.__requiredFiles) {
		return this._scripts[name];
	} else {
		for (var i in params.__requiredFiles) {
			var f = params.__requiredFiles[i];
			if (typeof f === 'string' && !this._scripts[f]) {
				return false;
			}
		}
	}

	return true;
};

/**
 * Saves in Cookie information about Window Width
 */
app.setMinWindSize = function () {
	var wd = $(window).width();
	this.cookies.createCookie('winWidth', wd);
	this.correctMobileLinks(wd);
};

/**
 * Checks form for Mandatory Fields
 *
 * @param {object} form
 *
 * @returns {bool}
 */
app.checkOrderForm = function(form) {
	var names = '';
	var $firstItem = null;
	var $form = $(form);
    $form.find('select.mandatory').each(
		function () {
			if (!$(this).val()) {
				names += '<b>' + $(this).addClass('invalid-item')
					.closest('li.option-line')
					.find('div.label label').html() + '</b>, ';
				if (!$firstItem) {
					$firstItem = $(this);
				}
			} else {
				$(this).removeClass('invalid-item');
			}
		}
	);
    $form.find('.prod-comments, .prod-comments-2').find('.form-fields input.required:not([type="radio"]):not([type="checkbox"]):visible , .form-fields select.required:visible, .form-fields textarea.required:visible').each(
		function () {
			var $this = $(this);
			var trId = $this.closest('tr').data('udu-id');
            var val = '';
			if ($this.attr('type') === 'file' && $this.hasClass('udu__upload-input')) {
                val = $('#upl-file-title-' + trId).val();
                if (!val) {
                	val = '';
				}
			} else {
                val = $this.val();
			}

			if (!val) {
				var comment = $this.addClass('invalid-item')
					.closest('tr')
					.find('.label label, .fld-td-check .check-txt, td:not(.fld-td-check) label')
					.html();
				comment = comment.slice(0, -1);
				names += '<b>' + comment + '</b>, ';
				if (!$firstItem) {
					$firstItem = $this;
				}
			} else {
				$(this).removeClass('invalid-item');
			}
		}
	);

    $form.find('tr.required').each(
		function () {
			var $this = $(this);
			var $items = $this.find('input[type="radio"]:visible, input[type="checkbox"]:visible');
			if ($items.length > 0 && $this.find('input[type="radio"]:checked, input[type="checkbox"]:checked').length === 0) {
				var comment = $this.find('.label label, .fld-td-check .check-txt, td:not(.fld-td-check) label')
					.html();
				comment = comment.slice(0, -1);
				names += '<b>' + comment + '</b>, ';
				if (!$firstItem) {
					$firstItem = $items;
				}
			}
		}
	);

	if (!app.prodInfo.inventoryPopupSelected && app.setOrderUserAddInformationContent) {
        app.setOrderUserAddInformationContent(null);
        app._popupParams = null;
	}

	var res = app.events.trigger(
		'udu_ON-PROD-FORM-SUBMIT',
		{
			$form: $form
		}
	);

	if (res.stopGo) {
		return false;
	}

	var errorCode = null;
	if (!names) {
		// here we check specific fields
		$form.find('input.email').each(
			function() {
				var $this = $(this);
				if (!app.forms.checkEmail($this.val())) {
					app.forms.markAsInvalid(
						$this,
						'_Incorrect_Email',
						true
					);
					names = '<b>' + $this.closest('tr').find('td.label label').html() + '</b>, ';
					errorCode = '_Incorrect_Email';
				};
				return false;
			}
		);
	}

	if (!names) {
		if (this.prodUserComments) {
			this.prodUserComments.setUserData();
		}
        var $qnt = $('#pr_form_id_quantity');
		if (app.prodInfo.inventoryPopup === 'Y' && this.prodOrderMode !== 'M' && !app.prodInfo.inventoryPopupSelected) {
			var avlHtml = $('#available-quantity').html();
			if (avlHtml !== '') {
                var avlQnt = Number(avlHtml);
			} else {
                avlQnt = null;
			}

			if ($qnt.hasClass('allowBackorder') && !avlQnt) {
                avlQnt = 0;
			}

			if (avlQnt || avlQnt === 0) {
				if (avlQnt < $qnt.val()) {
					app.getScript(
						'https://cdn.uducat.com/static/esb/js/main.prod.order.js',
						function() {
							app.showInventoryPopup(
								function (params) {
                                    app.prodInfo.inventoryPopupSelected = true;
									var fileParams = params;
									if ($qnt.hasClass('allowBackorder')) {
										fileParams.allowBackorder = 'Yes';
									} else {
										fileParams.allowBackorder = 'No';
									}
                                    fileParams.maxQty = avlQnt;
									fileParams.prodURL = String(document.location);
									app.setOrderUserAddInformationContent(fileParams);
                                    app._popupParams = params;
									$('.cart-form form').submit();
                                }	
							);
						},
						{
							block: true
						}
					);
					return false;
				}
			}
		} else if (app.prodInfo.inventoryPopup !== 'Y' && $qnt.hasClass('allowBackorder') && !app.prodInfo.inventoryPopupSelected) {
            app.getScript(
                'https://cdn.uducat.com/static/esb/js/main.prod.order.js',
                function() {
                    app.prodInfo.inventoryPopupSelected = true;
                    var fileParams = {
                        allowBackorder: 'Yes'
					};

                    fileParams.maxQty = avlQnt;
                    fileParams.prodURL = String(document.location);
                    app.prodOrderUtils.addCommentItems();
                    app.setOrderUserAddInformationContent(fileParams);
                    app._popupParams = {};
                    $('.cart-form form').submit();
                }
            );

            return false;
		}

        if (this.prodOrderMode !== 'M' && app._popupParams) {
            if (app._popupParams.orderMode === 'Y') {
                app.prodInfo.inventoryPopupSelected = false;
                return false;
			}
        }

		return true;
	}

    app.prodInfo.inventoryPopupSelected = false;
	names = names.slice(0, -2);

	var canShowAlert = true;

	if ($firstItem && $firstItem.closest('#udu-div-window').length > 0) {
		canShowAlert = false;
	}

	var dispErrCode = errorCode || '_Product_Require_Fields_Msg';

	if (canShowAlert) {
		app.errorMsg(
			dispErrCode,
			null,
			{
				replace: {
					'%NAMES%': names
				}
			}
		);
	}

	app.events.trigger(
		'udu_ERROR-ON-PRODUCT-SUBMIT',
		{
			names: names,
			$item: $firstItem
		}
	);

	if ($firstItem) {
		app.utils.effects.scrollTop($firstItem.offset().top - 150);
        app.balloon.showErrorByCode(
            $firstItem,
            '_You_Did_Not_Fill_Input'
        );
	}

	return false;
};

/**
 * Does specific action before we recalc data
 *
 * @param {Object} form
 * @returns {boolean}
 */
app.doBeforeCartRecalc = function(form) {
	var $tbl = $('table.show-inv-popup');
	if ($tbl.length === 1) {
		if ($(form).hasClass('inventory-popup-checked')) {
			return true;
		}
		app.getScript(
            'https://cdn.uducat.com/static/esb/js/main.prod.order.js',
			function() {
            	if (app.showCartInventoryPopup) {
					app.showCartInventoryPopup(form);
				}
			},
			{
				wait: true
			}
		);
		return false;
	}

	if (app.doCustomRecalcProcessing) {
		return app.doCustomRecalcProcessing(form);
	}

	if ($('.list.udu__reload-after-recalc').length > 0) {
		app.blockWindow(true, true);
		var $frm = $(form);
		$.post(
			$frm.attr('action'),
			{
				cartItemKey: $frm.find('input[name="cartItemKey"]').val(),
				quantity: $frm.find('input[name="quantity"]').val()
			},
			function() {
				app.url.goCart();
			}
		);

		return false;
	}

	return true;
};

/**
 * Initializes Scroll Button to got top by clicking
 *
 * @returns {void}
 */
app.initializeScrollButton = function() {
	$('body').append('<a id="back-top" href="#" class="v2">' + app.lang.selectWord('Scroll back to Top', 'Retour au haut de la page') + '</a>');
	$("#back-top").hide();
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('#back-top').fadeIn();
			} else {
				$('#back-top').fadeOut();
			}
		});
		$('#back-top').click(
			function (e) {
				app.utils.effects.scrollTop(0);
				if (app.focuser) {
					app.focuser.focus('.logo a');
				}
				e.stopPropagation();
				return false;
			}
		).keypress(
            function (e) {
                app.utils.effects.scrollTop(0);
                if (app.focuser) {
                    app.focuser.focus('.logo a');
                }
                e.stopPropagation();
                return false;
            }
		)
	});
};

/**
 * Returns right tab container
 *
 * @returns {Object}
 */
app.getRightTabWrapper = function() {
	var sel = '._ .udu-right-tab-wrapper';
	var $div = $(sel);
	if ($div.length === 0) {
		$('._').append('<div class="udu-right-tab-wrapper"></div>');
        $div = $(sel);
	}
	return $div;
};

/**
 * Returns right tab container
 *
 * @returns {Object}
 */
app.addRightTab = function(html) {
	var $div = app.getRightTabWrapper();
	var h = 0;
	$div.find('> div').each(
		function() {
			h += $(this).outerWidth() + 5;
		}
	);

	return $(html).appendTo($div)
		.css('margin-top', h);
};

/**
 * Forms URL based on Params
 *
 * @param url
 * @param params
 * @returns {*}
 */
app.formURL = function (url, params) {
	//todo:: remove when move to all.js everywhere
	if (app.url) {
		return app.url.formURL(url, params);
	}
	var token = url.indexOf('?') > 0 ? '&' : '?';
	if (Array.isArray(params)) {
		for (var i = 0; i < params.length; i++) {
			url += token + params[i].name + '=' + encodeURIComponent(params[i].val);
			token = '&';
		}
	} else {
		for (i in params) {
			url += token + i + '=' + encodeURIComponent(params[i]);
			token = '&';
		}
	}
	return url;
};

/**
 * Fixes some items in Boot St
 * @returns {boolean}
 */
app.fixBootStrap = function() {
	var $div = $('div.bootstrap');
	if ($div.length === 0) {
		return false;
	}

    var lay_obs = new MutationObserver(
        function(mutations) {
            mutations.forEach(
                function(mutation) {
                    if ($div.data('udu-init-done') !== 'Y') {
                        var $nm = $('div.bootstrap input[name=login]');
                        if ($nm.length > 0) {
                            var $nmLab = $nm.closest('.form-group').find('label.control-label');
                            var $psLab = $('div.bootstrap input[name=password]').closest('.form-group').find('label.control-label');
                            if (window.admin) {
                                $psLab.attr('data-udu-content-id', '_loginPagePassword')
                                    .attr('data-udu-save-method', 'file')
									.addClass('_v2');

                            	$nmLab.attr('data-udu-content-id', '_loginPageEmail')
									.addClass('_v2')
                                    .attr('data-udu-save-method', 'file');
                            }
                            if (app._vars._loginPageEmail) {
                                $nmLab.html(app._vars._loginPageEmail);
                            }
                            if (app._vars._loginPagePassword) {
                                $psLab.html(app._vars._loginPagePassword);
                            }
                            $div.data('udu-init-done', 'Y');
                            $div.addClass('_done');
                            $('.bootstrap button').attr('disabled', false).removeClass('btn btn-default');
                            lay_obs.disconnect();
                            if (String(document.location.hash).indexOf('/user/log-in') > 0 && app._vars._loginDocumentReferrer) {
                                app.setLData('documentReferrer', app._vars._loginDocumentReferrer);
                            }

                            if (app.doAfterBootStrapLoginCorrected) {
                                app.doAfterBootStrapLoginCorrected();
							}

                            return false;
                        }

                        var $tbl = $div.find('table.table-bordered');
                        if ($tbl.length === 0) {
                        	$tbl = $div.find('div.alert.alert-info');
						}
                        if ($tbl.length > 0) {
                            $div.data('udu-init-done', 'Y');
                            lay_obs.disconnect();
                            $('.bootstrap').addClass('_done');
                        	app.events.trigger(
                        		'udu_BOOTSTRAP-TABL-LOADED',
								{

								}
							);
                        	return false;
						}
                    }
                }
            );
        }
    );
    $div.each(
    	function() {
            lay_obs.observe(this, { attributes: true, childList: true, subtree:true, characterData: true });
		}
	);
};
app.user = {
    updateURL: null,
	getInfo: function(callBack) {
       app.getInfo(
		   'user-info',
		   function(data) {
			   data = JSON.parse(data);
			   app.user.updateURL = data.updateURL
			   if (callBack) {
                   callBack(data.data);
			   }

		   }
	   );
	},
	saveInfo: function(inData, callBack) {
		app.user.getInfo(
			function(data) {
				for (var i in inData) {
					data[i] = inData[i];
				}
				$.post(
					app.user.updateURL,
					data,
					function(str) {
						if (callBack) {
                            callBack(str);
						}
					}
				)
			}
		)
	}
};

app.hamburger = {
	_vars: {},
    init: function(options) {
        if (!options || typeof options !== 'object') {
            options = {};
        }
        app.color.init();
        app._vars.lastWndScrollY = $(window).scrollTop();
        $(window).scroll(
            function() {
                var needFix = false;
                var pos = $(window).scrollTop();
                if (pos < 250 || pos < app._vars.lastWndScrollY) {
                    needFix = true;
                }
                app._vars.lastWndScrollY = pos;

                if (needFix) {
                    $('body').addClass('_mob-fix');
                } else {
                    $('body').removeClass('_mob-fix');
                }
            }
        );
        var $body = $('body');
        $body.addClass('_mob-fix');

        var $hambSwitcher = $('.hamb-switcher');

        if (options.look) {
            $body.addClass('udu-hamb-look_' + options.look);
        }

        if (options.mode) {
			$body.addClass('hum-mode_' + options.mode);
		}

        if (options.mode === 1) {
            $hambSwitcher.before('<li class="li-top-search mob-only">' + app.hamburger.getSearchHtml() + '</li>');
            var $addItem = $('.for-ham-top-line');
            if ($addItem.length === 1) {
                $('.top-line').append('<div class="mob-visible add-ham-html">' + $addItem.html() + '</div>');
            }
        } else if (options.mode === 2) {
            var $myCartLink = $('a.my-cart-link, a.my-cart');
            if ($myCartLink.length > 0) {
            	var lnk = $myCartLink.attr('href');
				var $hs = $('li.hamb-switcher');
                $hs.before('<li class="mob-visible mob-only ham-top-add-items ham-top-my-cart"><a href="' + lnk + '"></a></li>');
                var $cnt = $('.my-cart-count');
                if ($cnt.length > 0) {
                	$('li.ham-top-my-cart').append('<span class="ham-my-cart-count">' + $cnt.html() + '</span>');
				}
                $hs.before('<li class="mob-visible mob-only ham-top-add-items ham-top-gear"><a></a></li>');
                $('.ham-top-add-items.ham-top-gear').mousedown(
                	function() {
                		app.hamburger.switchHamburger({
							addCss: "udu__show-gear-mob-only",
							openItem: "gear-item"
						});

					}
				)
			}
		} else if (options.mode === 3) {
			$cnt = $('.my-cart-count');
			$myCartLink = $('a.my-cart-link, a.my-cart');
			if ($myCartLink.length > 0) {
				$hs = $('li.hamb-switcher');
				lnk = $myCartLink.attr('href');
				$hs.before('<li class="mob-visible mob-only ham-top-add-items ham-top-my-cart"><a href="' + lnk + '"></a></li>');
				if ($cnt.length > 0) {
					$('li.ham-top-my-cart').append('<span class="ham-my-cart-count">' + $cnt.html() + '</span>');
				}
			}
		}

        var $topLine = $('.top .top-line');
        var logoHtml = '';
        $('div.logo:not(.udu__no-mobile-logo):not(.udu__second-logo)').each(
        	function() {
        		logoHtml += $(this).html().replace('id="header-logo"', 'id="header-logo-mob-v2"');
			}
		);

		if (options.logo) {
			logoHtml = logoHtml.replace(/src="[^\"]+"/, 'src="' + options.logo + '"');
		}

        $topLine.append('<div class="mob-logo">' + logoHtml + '</div>');

        $topLine.find('.mob-logo a').mousedown(
        	function() {
				if (app.doOnMobileLogoMouseDown) {
                    app.doOnMobileLogoMouseDown(this);
				}
			}
		);

        if (options.topLineBgColor) {
            $topLine.css('background-color', options.topLineBgColor);
        }

        $hambSwitcher.mousedown(
            function() {
                app.hamburger.switchHamburger(options);
            }
        );
    },
    switchHamburger: function(options) {
        var $hamburger = $('.top-hamburger');
        if ($hamburger.length === 0) {
            app.hamburger.createHamburgerMenu(options);
            setTimeout(
                function() {
                    app.hamburger.switchHamburger(options);
                },
                200
            );
            return false;
		}

        var $hambSwitcher = $('.hamb-switcher');
        var $hambSwitcherInner  = $hambSwitcher.find('.hamburger');

		var $body = $('body, html');

        if ($hamburger.hasClass('opened')) {
            if (options.openItem === 'gear-item') {
            	if (!$body.hasClass('_mob-open-by_main')) {
                    $hamburger.removeClass('opened');
                    $hambSwitcherInner.removeClass('is-active');
                    $body.removeClass('_mob-ham-opened');
				} else {
                    $body.toggleClass('_mob-open-by_gear-item');
				}
            } else {
                if (!$body.hasClass('_mob-open-by_gear-item')) {
                    $hamburger.removeClass('opened');
                    $hambSwitcherInner.removeClass('is-active');
                    $body.removeClass('_mob-ham-opened');
				} else {
					if ($hambSwitcherInner.hasClass('is-active')) {
                        $body.removeClass('_mob-open-by_main');
                        $hambSwitcherInner.removeClass('is-active');
					} else {
                        $body.addClass('_mob-open-by_main');
                        $hambSwitcherInner.addClass('is-active');
					}
				}
            }
        } else {
        	$body.removeClass('_mob-open-by_gear-item _mob-open-by_main');
            if (options.doBeforeShowMenu) {
                options.doBeforeShowMenu($hamburger);
            }
            $hamburger.addClass('opened');
            if (options.openItem === 'gear-item') {
                $body.addClass('_mob-open-by_gear-item');
			} else {
                $hambSwitcher.find('.hamburger').addClass('is-active');
                $body.addClass('_mob-open-by_main');
			}

            $body.addClass('_mob-ham-opened');
        }

        if (app.topWarn) {
			app.topWarn.fixTopLine();
		}

    },
    getSearchHtml: function(options) {
        var action = $('a.lnk-search').attr('href');
        if (!action) {
            action = $('#advanced-search-form, .top .div-search-field, div.quick-search').find('form').attr('action');
        }
        if (action) {
            var placeholder = app.lang.selectWord('Enter Product Name...', 'Entrer le nom du produit...');
            return '<div class="ham-search"><form method="get" action="' + action + '"><input name="text" placeholder="' + placeholder + '" class="custom-input"><button class="custom-button" type="submit"></button></form></div>';
        } else {
            return '';
        }
    },
    buildProdList: function(params) {
        if (!params.items) {
            return '';
        }
        var html = '<div class="mob-prod-wrapper open">';
        html += '<h3>' + params.title + '</h3>';
        var limit = 15;
        html += '<div class="mob-resently-viewed">';
        var noPhoto = app.getCSSDir().replace('/css', '/img/no-photo.gif');
        for (var a in params.items) {
            limit--;
            if (limit < 0) {
                break;
            }

            var prod = params.items[a];
            try {
                prod.url = prod.url.split('#')[0];
			} catch (e) {
            	continue;
			}

            html += '<div class="item">';
            html += '<div class="sku">' + prod.sku + '</div>';
            html += '<div class="image"><a href="' + prod.url + '"><img  onerror="this.src=\'' + noPhoto + '\'"  alt="' + prod.name + '" src="' + prod.img + '"></a></div>';
            html += '<div class="name"><a href="' + prod.url + '">' + prod.name + '</a></div>';
            html += '</div>';
        }

        if (limit === 15) {
            return '';
        }

        html += '</div></div>';
        return html;
    },
    createHamburgerMenu: function(options) {
        var html = '<div class="top-hamburger udu__scroller-1">';

        if (options.mode !== 1) {
            html += app.hamburger.getSearchHtml(options);
        }

        var uniqURLs = {};
        var tmp = ['a.logo', 'a.log-in', 'a.my-account', 'a.reg-me', 'a.log-out', 'a.currency', 'a.lang', 'a.my-cart-link, a.my-cart', '.li-block a.all-products', 'a.udu__my-budgets-link'];
        var mainLinksHtml = '';
        tmp.forEach(
            function (sel) {
                var $this = $(sel);
                if ($this.length > 0) {
                    var href = $this.attr('href');
                    var css = 'ham-fa';
                    var txt = $this.html();
                    var className = $this[0].className;
                    if (className) {
                        var tmpClassArr = className.split(' ');
                        tmpClassArr.forEach(
                            function (c) {
                                if (c !== 'fa' && c) {
                                    css += ' ham-' + c;
                                }
                            }
                        );
                    }
                    var addHtml = '';
                    if (!uniqURLs[href]) {
                        if ($this.hasClass('my-cart-link') || $this.hasClass('my-cart')) {
                        	txt = app.lang.selectWord('Cart', 'Panier');
                            var $tmp = $('.my-cart-count');
                            if ($tmp.length === 1) {
                                var cnt = $tmp.html();
                                if (Number(cnt)) {
                                	txt += ' (' + cnt + ')';
								}
                            }
                        } else if ($this.hasClass('udu__my-budgets-link')) {
                            txt = app.lang.selectWord('Budget', 'Budget');
                            var $b = $this.find('span.money');
                            if ($b.length === 1) {
                                cnt = $b.html();
                                if (Number(cnt)) {
                                    txt += ' (' + cnt + ')';
                                }
							}
                        } else if ($this.hasClass('logo')) {
                            txt = 'Home';
                        }
                        mainLinksHtml += '<li class="' + css + '"><a data-href="' + href + '"><span>' + txt + '</span>' + addHtml + '</a></li>';
                    }
                    uniqURLs[href] = true;
                }
            }
        );

        if (mainLinksHtml) {
            html += '<ul class="_mob-main-ul">' + mainLinksHtml + '</ul>';
        }

        html +='<ul>';

        $('.top-line ul li:not(.n-pop):not(.mob-visible) a:not(.n-pop), .menu > ul:not(.n-pop)').each(
            function() {
                var $this = $(this);
                if ($this.get(0).nodeName === 'A') {
                    var href = $(this).attr('href');
                    if (!href) {
                        href = '';
                    }
                    var txt = $this.html();
                    if (!uniqURLs[href] && !Number(txt)) {
                        html += '<li><a href="' + href + '">' + txt + '</a></li>';
                    }
                    uniqURLs[href] = true;
                } else {
                    html += $this.html();
                }
            }
        );
        html += '</ul>';

        $('.d2 .li-block:not(.n-pop):not(.blog-popular)').each(
            function() {
                var $this = $(this);
                var cssClass = '_mob-li-block li-block';
                if ($this.hasClass('blog-popular')) {
                    cssClass += ' blog-popular';
                }
                html += '<div class="' + cssClass + '">' + $this.html() + '</div>';
            }
        );

        if (app.wishList && app.wishList.getCount() > 0) {
            html += this.buildProdList({
                title: (app.getLang() === 'en' ? 'My wish list' : 'Ma liste d\'envies'),
                items: app.getLData('__udu_wishList')
            });
        }

        var saveKey = 'udu-lastSeenProducts:' + app.storeId;
        var lastVisitProducts = app.getLData(saveKey);

        html += this.buildProdList({
            title: app.lang.selectWord('Recently viewed Products', 'Derniers produits consultés'),
            items: lastVisitProducts
        });


        html += '</div>';

        html = html.replace(/ul-mega-/g, 'ul-mob-mega-');
        html += '<div class="_mob-blocker"></div>';

        var $top = $('._ > .top');
        html = html.replace(/<script[^>]*>[\S\s]*?<\/script>/g,'');
        $top.after(html);

        if (app.accessibilty) {
            app.accessibilty.makeUniqIDS();
		}

        if (app.fonts.fontMaterialIconsExists()) {
        	$('.top-hamburger').addClass('udu__material-icons').find('._mob-main-ul li').addClass('udu__material-icon');
		} else if (app.fonts.fontLineariconsExists()) {
            $('.top-hamburger').addClass('udu__linear-icons').find('._mob-main-ul li').addClass('udu__linear-icons');
		}

        $('._mob-main-ul li a').click(
            function (e) {
                e.preventDefault();
                var url = $(this).data('href');
                app.blockWindow(
                    true,
                    true,
                    function () {
                        window.location = url;
                    }
                )
            }
        );

        $('.mob-prod-wrapper h3').click(
            function() {
                var $div = $(this).closest('.mob-prod-wrapper');
                $div.toggleClass('open');
            }
        );

        var $mobBlock = $('._mob-li-block');

        $mobBlock.find('.level3, .level4').remove();

        for (var i = 1; i < 3; i++) {
            $mobBlock.find('ul.level' + i)
                .each(
                    function() {
                        $(this).attr('class', 'ul-mob-mega-' + i).closest('li').addClass('with-children');
                    }
                );
        }

        setTimeout(
            function() {
                $('.ham-search input').focus();
            },
            1000
        );

        $('.top-hamburger *').removeAttr('id');
        $('.top-hamburger .tree-node').removeClass('tree-node');
        $('.top-hamburger div.node, .top-hamburger .n-pop, .top-hamburger .udu__top-category-image-mode').remove();

		if (app.events) {
			app.events.trigger('udu_HAMBURGER-POPUP-CREATED', {html: html});
		}

        $('.top-hamburger .with-children > div > a:not(.udu__use-native-link)').click(
            function(e) {
                $(this).closest('li').toggleClass('opened');
                e.preventDefault();
                e.stopPropagation();
            }
        );

		$('.top-hamburger .udu__u-menu-counter').remove();

        return $('.top-hamburger');
    }
};

app.tabs = {
    _vars: {},
    init: function () {
        $('.udu-tabs__headers:not(._done) li').click(
            function() {
                var $this = $(this);
                var id = $this.data('tab-id');
                app.events.trigger('udu_TAB-SELECT', {id: id});
                $this.closest('.udu-tabs__headers').find('>li.selected').removeClass('selected');
                $this.addClass('selected');
                var $tBody = $('.udu-tabs__bodies li[data-tab-id=' + id +']');
                $tBody.closest('.udu-tabs__bodies').find('>li.selected').removeClass('selected');
                $tBody.addClass('selected');
            }
        );
        $('.udu-tabs__headers').addClass('_done');
    }
};

app.__version = function() {
	if (top !== window) {
		return false;
	}
	var colors = ['#5bcaf5', '#ffcc00', '#00d4ab', '#559cd4', '#ff7f2b', '#009482', '#f79f5b', '#d33a2a', '#559cd4', '#1b5a7c'];
	var st = 'display: inline-block; line-height: 80px; padding: 0 10px; font-size: 64px; color: white; background-color:';
	var arr = colors.map(
        function(c) {
            return st + c + ';';
        }
    );
	arr.unshift('%cU%cD%cU%cC%cA%cT%c.%cC%cO%cM');
    console.log.apply(this, arr);
	console.log('Copyright ' + (new Date()).getFullYear() + ' - Uducat.com. PromoBullit, Build Your Brand, Deals of the Week are trademarks of uducat.com Corporation.');
    return '';
};

try {
    app.__version();
} catch(e) {

}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position)
            || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}
if (!String.prototype.repeat) {
    String.prototype.repeat = function(count) {
        'use strict';
        if (this == null) {
            throw new TypeError('can\'t convert ' + this + ' to object');
        }
        var str = '' + this;
        count = +count;
        if (count < 0) {
            throw new RangeError('repeat count must be non-negative');
        }
        if (count == Infinity) {
            throw new RangeError('repeat count must be less than infinity');
        }
        count |= 0;
        if (str.length == 0 || count == 0) {
            return '';
        }
        if (str.length * count >= (1 << 28)) {
            throw new RangeError('repeat count must not overflow maximum string size');
        }
        while (count >>= 1) {
            str += str;
        }
        str += str.substring(0, str.length * count - str.length);
        return str;
    }
}

if (!app.ajaxProcessURLs) {
	try {
		app.ajaxProcessURLs = app.top().app.ajaxProcessURLs;
	} catch (e) {

	}
}